import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  InputLabel,
  Input,
  Button,
  FormHelperText,
  Snackbar,
} from '@material-ui/core';
import Compressor from 'compressorjs';

import FileList from './FileList';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
}));

function Upload({
  field,
  upload,
  name,
  errors,
  imageOnly,
  disabled,
  helperText,
  fullWidth,
  onChange,
  value,
  multiple,
  maxNum,
  maxSize,
  capture,
  // use to add style to file list link
  linkStyle,
  // AWS upload function
  AWSUpload,
  getAWSUploadUrls,
  compress,
  compressToSize,
}) {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(false);

  const handleChange = async event => {
    if (multiple && (value || []).length >= maxNum) {
      setSnackbar(<Trans>File num cannot exceed 1.</Trans>);
      return;
    }

    const fileList = event.target.files;

    if (fileList.length) {
      let url = '';
      let updatedValue = null;
      const selectedFile = fileList[0];
      const { size, type } = selectedFile;
      if (size > maxSize) {
        setSnackbar(<Trans>File size cannot exceed 5M.</Trans>);
        return;
      }
      if (
        typeof AWSUpload === 'function' &&
        typeof getAWSUploadUrls === 'function'
      ) {
        const imgType = type.slice(type.indexOf('/') + 1);

        // get the 'put_file_url' and 'get_file_url' to make upload request
        const res = await getAWSUploadUrls(imgType);
        const { put_file_url: putFileUrl, get_file_url: getFileUrl } = res.data;

        // use 'put_file_url' to upload image to s3
        const uploadRes = await AWSUpload(putFileUrl, selectedFile);

        url = getFileUrl || uploadRes.url;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (compress) {
          let quality = 1;
          if (
            selectedFile.size > 1024 * 1024 * 0.5 &&
            selectedFile.size <= 1024 * 1024
          ) {
            quality = 0.5;
          } else if (
            selectedFile.size > 1024 * 1024 &&
            selectedFile.size < 1024 * 1024 * 2
          ) {
            quality = 0.25;
          } else if (
            selectedFile.size >= 1024 * 1024 * 2 &&
            selectedFile.size < 1024 * 1024 * 3
          ) {
            quality = 0.2;
          } else if (selectedFile.size >= 1024 * 1024 * 3) {
            quality = 0.1;
          }
          // eslint-disable-next-line no-new
          new Compressor(selectedFile, {
            quality,
            maxHeight: 2000,
            convertSize: compressToSize, // means PNG file which is over compressToSize will be compressed
            success(result) {
              if (result.size > compressToSize) {
                // for safe
                // TODO: optimize this logic
                // eslint-disable-next-line no-new
                new Compressor(result, {
                  quality: quality / 5,
                  maxHeight: 1500,
                  convertSize: compressToSize,
                  success(result2) {
                    const formData = new FormData();
                    formData.append('file', result2, selectedFile.name);
                    url = upload(formData);
                    url.then(updatedUrl => {
                      if (updatedUrl) {
                        updatedValue = [updatedUrl];
                        onChange(updatedValue);
                      }
                    });
                  },
                });
              } else {
                const formData = new FormData();
                formData.append('file', result, selectedFile.name);
                url = upload(formData);
                url.then(updatedUrl => {
                  updatedValue = [updatedUrl];
                  onChange(updatedValue);
                });
              }
            },
          });
        } else {
          const formData = new FormData();
          formData.append('file', selectedFile);
          url = await upload(formData);
        }
      }
      if (!url) {
        return;
      }
      updatedValue = url;
      if (multiple) {
        updatedValue = value ? [...value, url] : [url];
      }
      onChange(updatedValue);
    }
  };

  const handleRemove = index => {
    if (typeof index === 'number') {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    } else onChange(null);
  };

  return (
    <>
      <InputLabel htmlFor={`${field}${name}`}>
        <Input
          id={`${field}${name}`}
          disabled={disabled}
          className={classes.input}
          type="file"
          onChange={handleChange}
          inputProps={
            capture
              ? {
                  accept: 'image/*',
                  capture: 'environment',
                }
              : {
                  accept: imageOnly
                    ? 'image/jpeg,image/png'
                    : 'application/pdf,image/jpeg,image/png',
                }
          }
          value=""
        />
        <Box bgcolor="background.paper" my={2} color="error.main">
          <Button
            component="span"
            variant="outlined"
            fullWidth={fullWidth}
            color="secondary"
          >
            <Trans>Upload File</Trans>
          </Button>
        </Box>
      </InputLabel>
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
      <Box mt={2}>{helperText}</Box>
      {value instanceof Array && (
        <Box mt={4} style={{ wordBreak: 'break-all' }}>
          {value.map((item, index) => (
            <FileList
              key={item}
              url={item}
              linkStyle={linkStyle}
              handleRemove={() => handleRemove(index)}
            />
          ))}
        </Box>
      )}
      {typeof value === 'string' && (
        <Box mt={4}>
          <FileList
            url={value}
            linkStyle={linkStyle}
            handleRemove={() => handleRemove()}
          />
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!snackbar}
        onClose={() => setSnackbar(false)}
      >
        <Box
          color="background.paper"
          bgcolor="error.main"
          px={8}
          py={2}
          borderRadius={4}
        >
          <Typography variant="h5" color="inherit">
            {snackbar}
          </Typography>
        </Box>
      </Snackbar>
    </>
  );
}

Upload.defaultProps = {
  errors: {},
  name: '',
  field: '',
  imageOnly: false,
  disabled: false,
  helperText: null,
  fullWidth: true,
  value: null,
  multiple: false,
  maxNum: 20,
  maxSize: 10485760,
  capture: false,
  linkStyle: '',
  upload: () => {},
  AWSUpload: undefined,
  getAWSUploadUrls: undefined,
  compress: false,
  compressToSize: 0,
};

Upload.propTypes = {
  upload: PropTypes.func,
  AWSUpload: PropTypes.func,
  getAWSUploadUrls: PropTypes.func,
  errors: PropTypes.object,
  name: PropTypes.string,
  field: PropTypes.string,
  imageOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.node,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  maxNum: PropTypes.number,
  maxSize: PropTypes.number,
  capture: PropTypes.bool,
  linkStyle: PropTypes.string,
  compress: PropTypes.bool,
  compressToSize: PropTypes.number,
};

export default Upload;
