import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Grid } from '@material-ui/core';

import { Colors } from 'variables';

const useStyles = makeStyles(({ spacing }) => ({
  label: {
    fontSize: 14,
    color: Colors.grey,
    marginBottom: spacing(2),
  },
}));

function DetailBox({ label, children }) {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={3}>
      <Box mt={6}>
        <Typography className={classes.label} variant="subtitle1">
          {label}
        </Typography>
        {children}
      </Box>
    </Grid>
  );
}

DetailBox.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default DetailBox;
