import { format, utcToZonedTime } from 'date-fns-tz';

function getFormattedTimeZonedDate(date, dateFormat = 'dd MMM yyyy') {
  if (!date) return '';
  const timeZoneOffset = new Date().getTimezoneOffset();
  const localDate = utcToZonedTime(date, timeZoneOffset);
  return format(localDate, dateFormat);
}

export default getFormattedTimeZonedDate;
