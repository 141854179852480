import React from 'react';
import { Box, Divider } from '@material-ui/core';

import Brand from './Brand';
import Contact from './Contact';
import CopyRight from './CopyRight';

function Footer() {
  return (
    <Box overflow="hidden" flexShrink="0" mt="auto">
      <Divider />
      <Brand />
      <Box bgcolor="primary.contrastText" pt={6}>
        <Contact />
        <CopyRight />
      </Box>
    </Box>
  );
}

export default Footer;
