import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { Grid, Divider, Typography, Box } from '@material-ui/core';

import { Colors } from 'variables';
import api from '@platform/api';
import MessageBar from 'components/MessageBar';
import { numberWithPoint } from 'utils/number';
import getFormattedTimeZonedDate from '../utils/dateFormatter';

import ClaimButton from './RoutedButton';
import ClaimStatus from './constants/ClaimStatus';
import ClaimInfoBox from './components/InfoBox';
import DetailBox from './components/DetailBox';
import ClaimStatusLabel from './components/ClaimStatusLabel';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
  },
  subtitle: {
    color: Colors.dimGray,
  },
  reminderText: {
    fontSize: 10,
    color: Colors.white,
  },
  closeIcon: {
    color: Colors.dimGray,
    fontSize: 16,
  },
}));

function ClaimItem({
  data,
  history,
  policyId,
  setReload,
  handleReSubmitClaim,
}) {
  const classes = useStyles();
  const {
    state,
    claimId,
    cancelAt,
    reimburse,
    approvedAt,
    rejectedAt,
    submittedAt,
    rejectReason,
    pendingReason,
    approvedAmount,
  } = data;
  const [apiError, setApiError] = useState(false);

  const errorMsg = rejectReason || get(reimburse, 'reimburseFailedReason', '');

  const fullName = get(data, 'claimForm.personal_details.name', '');

  const reimburseState = get(reimburse, 'state', '');
  const handleCancelClaim = async () => {
    try {
      await api.cancelClaim(policyId, claimId);
      setReload(true);
    } catch (e) {
      setApiError(
        <Trans>
          Something went wrong, please contact our customer service.
        </Trans>
      );
    }
  };

  const handleCancelReimburse = async () => {
    try {
      await api.cancelReimburse(policyId, claimId, get(reimburse, 'id'));
      setReload(true);
    } catch (e) {
      setApiError(
        <Trans>
          Something went wrong, please contact our customer service.
        </Trans>
      );
    }
  };

  const handleReimburse = () =>
    history.push(`/reimbursement/?claimId=${claimId}&policyId=${policyId}`);

  return (
    <Box mt={6} p={6} bgcolor={Colors.white} border="1px solid #EBECF0">
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item sm="auto" xs={12}>
          <Typography variant="subtitle1" className={classes.title}>
            {fullName}
          </Typography>
          <Box>
            <Typography variant="caption">
              <span className={classes.subtitle}>
                <Trans>Claimant</Trans>
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item sm="auto" xs={12}>
          <ClaimButton
            id={claimId}
            state={state}
            reimburseState={reimburseState}
            handleReimburse={handleReimburse}
            handleCancelClaim={handleCancelClaim}
            handleCancelReimburse={handleCancelReimburse}
            handleReSubmitClaim={() => handleReSubmitClaim(data)}
          />
        </Grid>
      </Grid>
      <ClaimInfoBox
        state={state}
        rejectReason={errorMsg}
        pendingReason={pendingReason}
        reimburseState={reimburseState}
      />
      <Box mt={4}>
        <Divider light />
      </Box>
      <Grid container>
        <DetailBox label={<Trans>Claim Status</Trans>}>
          <ClaimStatusLabel status={state} />
        </DetailBox>
        <DetailBox label={<Trans>Claim Submission Date</Trans>}>
          <Typography variant="subtitle1">{submittedAt}</Typography>
        </DetailBox>
        {!!cancelAt && new Date(cancelAt).getTime() > 0 && (
          <DetailBox label={<Trans>Claim Cancelled Date</Trans>}>
            <Typography variant="subtitle1">{cancelAt}</Typography>
          </DetailBox>
        )}
        {!!approvedAt && new Date(approvedAt).getTime() > 0 && (
          <DetailBox label={<Trans>Claim Approval Date</Trans>}>
            <Typography variant="subtitle1">{approvedAt}</Typography>
          </DetailBox>
        )}
        {state === ClaimStatus.CLAIM_APPROVED && approvedAmount > 0 && (
          <DetailBox label={<Trans>Claim Amount</Trans>}>
            <Typography variant="subtitle1">
              Rp {numberWithPoint(approvedAmount)}
            </Typography>
          </DetailBox>
        )}
        {!!rejectedAt && new Date(rejectedAt).getTime() > 0 && (
          <DetailBox label={<Trans>Claim Rejected Date</Trans>}>
            <Typography variant="subtitle1">{rejectedAt}</Typography>
          </DetailBox>
        )}
      </Grid>
      {!isEmpty(reimburse) && <ReimburseDetails reimburse={reimburse} />}
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </Box>
  );
}

const ReimburseDetails = ({ reimburse }) => {
  const { state, submitAt, failedAt, cancelAt, reimbursedAt } = reimburse;
  return (
    <>
      <Box mt={4}>
        <Divider light />
      </Box>
      <Grid container>
        <DetailBox label={<Trans>Reimbursement Status</Trans>}>
          <ClaimStatusLabel status={state} />
        </DetailBox>
        {!!submitAt && new Date(submitAt).getTime() > 0 && (
          <DetailBox label={<Trans>Reimbursement Submission Date</Trans>}>
            <Typography variant="subtitle1">
              {getFormattedTimeZonedDate(submitAt * 1000)}
            </Typography>
          </DetailBox>
        )}
        {!!cancelAt &&
          new Date(cancelAt).getTime() > 0 &&
          state === ClaimStatus.REIMBURSEMENT_CANCELLED && (
            <DetailBox label={<Trans>Reimbursement Cancelled Date</Trans>}>
              <Typography variant="subtitle1">
                {getFormattedTimeZonedDate(cancelAt * 1000)}
              </Typography>
            </DetailBox>
          )}
        {!!reimbursedAt && new Date(reimbursedAt).getTime() > 0 && (
          <DetailBox label={<Trans>Reimbursement Completed Date</Trans>}>
            <Typography variant="subtitle1">
              {getFormattedTimeZonedDate(reimbursedAt * 1000)}
            </Typography>
          </DetailBox>
        )}
        {!!failedAt &&
          new Date(failedAt).getTime() > 0 &&
          state === ClaimStatus.REIMBURSEMENT_FAILED && (
            <DetailBox label={<Trans>Reimbursement Failed Date</Trans>}>
              <Typography variant="subtitle1">
                {getFormattedTimeZonedDate(failedAt * 1000)}
              </Typography>
            </DetailBox>
          )}
      </Grid>
    </>
  );
};

ReimburseDetails.propTypes = {
  reimburse: PropTypes.shape({
    state: PropTypes.string.isRequired,
    submitAt: PropTypes.number,
    failedAt: PropTypes.number,
    cancelAt: PropTypes.number,
    reimbursedAt: PropTypes.number,
  }).isRequired,
};

ClaimItem.propTypes = {
  data: PropTypes.shape({
    claimId: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    cancelAt: PropTypes.string.isRequired,
    approvedAt: PropTypes.string.isRequired,
    rejectedAt: PropTypes.string.isRequired,
    submittedAt: PropTypes.string.isRequired,
    rejectReason: PropTypes.string.isRequired,
    pendingReason: PropTypes.string.isRequired,
    reimburse: PropTypes.object.isRequired,
    approvedAmount: PropTypes.number.isRequired,
  }).isRequired,
  history: RRPropTypes.history.isRequired,
  setReload: PropTypes.func.isRequired,
  policyId: PropTypes.string.isRequired,
  handleReSubmitClaim: PropTypes.func.isRequired,
};

export default ClaimItem;
