import React, { useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box } from '@material-ui/core';

import Form from 'components/Form';
import Typography from './components/AllianzTypography';

import { PROVINCES } from '../claim/constant';

const Address = ({ errors, address, handleChange }) => {
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const postalCodeRef = useRef(null);
  const addressLineRef = useRef(null);

  const field = 'address';

  const list = [
    {
      field,
      type: 'input',
      required: true,
      ref: addressLineRef,
      name: 'addressLine1',
      errors: errors[field],
      title: <Trans>Address Line1</Trans>,
      value: get(address, 'addressLine1'),
    },
    {
      field,
      type: 'input',
      name: 'addressLine2',
      title: <Trans>Address Line2 (optional)</Trans>,
      value: get(address, 'addressLine2'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: cityRef,
      name: 'city',
      errors: errors[field],
      title: <Trans>City</Trans>,
      value: get(address, 'city'),
    },
    {
      field,
      type: 'select',
      required: true,
      ref: stateRef,
      name: 'state',
      errors: errors[field],
      options: PROVINCES,
      placeHolder: <Trans>- Select -</Trans>,
      title: <Trans>State</Trans>,
      value: get(address, 'state'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: postalCodeRef,
      name: 'postalCode',
      errors: errors[field],
      title: <Trans>Postal Code</Trans>,
      value: get(address, 'postalCode'),
    },
  ];
  return (
    <Box bgcolor="#FAFCFD" marginY={4} padding={2}>
      <Box marginY={4}>
        <Typography variant="h5">
          <Trans>Address</Trans>
        </Typography>
        <Form list={list} handleChange={handleChange} />
      </Box>
    </Box>
  );
};

Address.propTypes = {
  errors: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Address;
