import React, { useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Select, MenuItem } from '@material-ui/core';

import Form from 'components/Form';
import { ktpReg, phoneReg, emailReg } from 'constant/regex';
import down from './img/down.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: 100,
    padding: `14px 10px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
  },
  select: {
    width: 95,
    marginLeft: -16,
    '&::after': {
      border: 'none',
    },
    '&::before': {
      border: 'none',
    },
  },
  img: { position: 'relative', right: 20 },
}));

const prefixes = [
  '0811',
  '0812',
  '0813',
  '0821',
  '0822',
  '0823',
  '0852',
  '0853',
  '0851',
];

const InsuredDetails = ({
  errors,
  prefix,
  handleChange,
  prefixChange,
  insuredDetails,
}) => {
  const styles = useStyles();
  const fullNameRef = useRef(null);
  const birthRef = useRef(null);
  const ktpRef = useRef(null);
  const emailRef = useRef(null);
  const mobileRef = useRef(null);

  const field = 'insuredDetails';
  const insuredList = [
    {
      field,
      type: 'input',
      required: true,
      ref: fullNameRef,
      name: 'fullName',
      errors: errors[field],
      title: <Trans>Full Name</Trans>,
      value: get(insuredDetails, 'fullName'),
    },
    {
      field,
      type: 'date',
      required: true,
      ref: birthRef,
      name: 'dateOfBirth',
      disableFuture: true,
      errors: errors[field],
      placeholder: 'DD / MM / YYYY',
      title: <Trans>Date of Birth</Trans>,
      value: get(insuredDetails, 'dateOfBirth'),
      helperText: (
        <Trans>The minimum age of the insured is 17 years old.</Trans>
      ),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: ktpRef,
      name: 'ktpNumber',
      errors: errors[field],
      regex: ktpReg,
      title: <Trans>KTP Number </Trans>,
      InputProps: { type: 'number' },
      value: get(insuredDetails, 'ktpNumber'),
      errorMsg: <Trans>Wrong KTP Number</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      ref: emailRef,
      name: 'emailAddress',
      regex: emailReg,
      errors: errors[field],
      InputProps: { type: 'email' },
      title: <Trans>Email Address</Trans>,
      value: get(insuredDetails, 'emailAddress'),
      errorMsg: <Trans>Wrong Email Address</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      ref: mobileRef,
      name: 'mobileNumber',
      placeholder: 'XXXX-XXXX',
      startAdornment: (
        <Select
          value={prefix}
          variant="filled"
          className={styles.select}
          classes={{ root: styles.root }}
          onChange={prefixChange}
          IconComponent={() => (
            <img src={down} alt="down" className={styles.img} />
          )}
        >
          {prefixes.map(item => {
            return (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            );
          })}
        </Select>
      ),
      errors: errors[field],
      regex: phoneReg,
      InputProps: { type: 'number' },
      title: <Trans>Mobile Number</Trans>,
      value: get(insuredDetails, 'mobileNumber'),
      errorMsg: <Trans>Wrong Phone Number</Trans>,
      helperText: (
        <Trans>
          Numbers only, please omit &quot; &quot;(space) or &quot;-&quot;
        </Trans>
      ),
    },
  ];

  return (
    <Box marginY={4} padding={2}>
      <Form list={insuredList} handleChange={handleChange} />
    </Box>
  );
};

InsuredDetails.propTypes = {
  errors: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  prefixChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  insuredDetails: PropTypes.object.isRequired,
};

export default InsuredDetails;
