import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { camelizeKeys } from 'humps';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Container, LinearProgress } from '@material-ui/core';

import api from '@platform/api';
import { setUser } from 'app/userSlice';

import PolicyItem from './PolicyItem';
import UserInfo from '../../components/UserInfo';
import getFormattedTimeZonedDate from '../utils/dateFormatter';

import { setPolicies } from './policiesSlice';

const useStyle = makeStyles(({ breakpoints }) => ({
  content: {
    margin: 'auto',
    minHeight: 500,
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const PolicyList = ({ history }) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const user = useSelector(state => state.user);

  const [loading, setLoading] = useState(false);

  const policies = useSelector(state => state.policies);

  useEffect(() => {
    if (policies.length) {
      setLoading(true);
      api
        .getPolicies()
        .then(res => {
          const policyList = get(res, 'data.results', []);
          const userInfo =
            camelizeKeys(
              get(policyList[0], 'activation.activation_form.insured_details')
            ) || {};
          dispatch(setUser(userInfo));
          dispatch(setPolicies(policyList));
        })
        .catch(() => {
          history.push('/error');
        })
        .finally(() => setLoading(false));
    }
  }, [reload]);

  const parsePolicy = result => {
    const policy = get(result, 'policy', {});
    const endDate = getFormattedTimeZonedDate(get(policy, 'end_at', 0) * 1000);
    const startDate = getFormattedTimeZonedDate(
      get(policy, 'start_at', 0) * 1000
    );

    const claims = get(result, 'claims', []) || [];
    const claimList = claims.map(claim => ({
      reason: get(claim, 'claim_type', ''),
      claimId: get(claim, 'id', ''),
      state: get(claim, 'state', ''),
      claimForm: get(claim, 'claim_form', {}),
      claimType: get(claim, 'claim_type', ''),
      approvedAmount: get(claim, 'approved_amount', 0),
      rejectReason: get(claim, 'reject_reason', ''),
      pendingReason: get(claim, 'pending_reason', ''),
      approvedAt: getFormattedTimeZonedDate(
        get(claim, 'approved_at', 0) * 1000
      ),
      rejectedAt: getFormattedTimeZonedDate(get(claim, 'reject_at', 0) * 1000),
      submittedAt: getFormattedTimeZonedDate(get(claim, 'submit_at', 0) * 1000),
      cancelAt: getFormattedTimeZonedDate(get(claim, 'cancel_at', 0) * 1000),
      reimburse: camelizeKeys(get(claim, 'reimburse', {})),
    }));

    return {
      policy: camelizeKeys(policy),
      policyId: get(policy, 'id'),
      displayPolicyId: get(policy, 'ex_policy_id'),
      state: get(policy, 'state', ''),
      claimable: get(policy, 'claim_enable', false),
      coveragePeriod: `${startDate} - ${endDate}`.trim(),
      claimList,
    };
  };

  return (
    <>
      <UserInfo user={user} />
      <Container maxWidth="lg">
        <Box className={styles.content}>
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <Box my={4}>
                {policies.map(result => (
                  <PolicyItem
                    history={history}
                    setReload={setReload}
                    key={get(result, 'policy.id', '')}
                    data={parsePolicy(result)}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

PolicyList.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default PolicyList;
