import React from 'react';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ErrorOutlined from '@material-ui/icons/ErrorOutlined';

import { Colors } from 'variables';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 500,
  },
  icon: {
    color: Colors.primaryMain,
    fontSize: theme.spacing(20),
  },
  message: {
    marginTop: 40,
    textAlign: 'center',
  },
}));

function ErrorPage() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <ErrorOutlined className={classes.icon} />
      <Typography className={classes.message} variant="h2">
        <Trans>Not Authorized</Trans>
      </Typography>
      <Typography className={classes.message} paragraph>
        <Trans>User is not authorized to access this url.</Trans>
      </Typography>
    </Box>
  );
}

export default ErrorPage;
