import React from 'react';
import { Trans } from '@lingui/macro';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BottomIcon from '../components/BottomIcon';
import Typography from '../components/AllianzTypography';

import errorWarning from '../img/error.svg';
import allianzTelkomselIcon from '../img/allianz-telkomsel.svg';

const useStyle = makeStyles(() => ({
  submit: {
    backgroundColor: '#E4003A',
    color: 'white',
  },
}));

const ErrorCode = () => {
  const styles = useStyle();
  return (
    <>
      <Box display="flex" justifyContent="center">
        <img src={allianzTelkomselIcon} alt="allianz-telkomsel" />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box
          display="flex"
          marginTop={10}
          marginBottom={6}
          alignItems="center"
          justifyContent="center"
        >
          <img src={errorWarning} alt="error warning" />
        </Box>
        <Typography variant="subtitle1" color="error">
          <Trans>
            Mohon maaf kode voucher Asuransi paket data Telkomsel yang Anda
            masukkan salah atau sudah tidak berlaku
          </Trans>
        </Typography>
        <Box marginTop={5} width="100%">
          <Button
            variant="contained"
            fullWidth
            className={styles.submit}
            onClick={() => {
              window.location = 'https://my.telkomsel.com/';
            }}
          >
            <Trans>Beli Paket Data Telkomsel Proteksi</Trans>
          </Button>
        </Box>
      </Box>
      <BottomIcon />
    </>
  );
};

export default ErrorCode;
