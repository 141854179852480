export default function calculateAge(birthday) {
  const date = new Date(birthday);
  if (date !== 'Invalid Date') {
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs);
    const age = ageDate.getUTCFullYear() - 1970;
    return age > 0 ? age : 0;
  }
  throw new Error('Invalid Date');
}
