import React from 'react';
import { Container, Box, Typography, Link } from '@material-ui/core';

import { ReactComponent as PhoneIcon } from 'images/phone.svg';
import { ReactComponent as EmailIcon } from 'images/email.svg';

const Contact = () => (
  <Container>
    <Box display="flex" pb={12}>
      <Box display="flex" flex={1} />
      <Typography component="div" variant="body1" color="primary">
        <Link href="tel:2139506605">
          <Box display="flex" alignItems="center" mr={6}>
            <PhoneIcon />
            <Box ml={3.25} fontWeight={500}>
              1500 136
            </Box>
          </Box>
        </Link>

        <Link href="mailto:cs@allianz.co.id">
          <Box display="flex" alignItems="center" mt={2} mr={6}>
            <EmailIcon />
            <Box ml={3.25} fontWeight={500}>
              cs@allianz.co.id
            </Box>
          </Box>
        </Link>
      </Typography>
    </Box>
  </Container>
);

export default Contact;
