const CLAIMED_BENEFIT = [
  {
    rate: 1,
    label: 'Kehilangan pengelihatan total dan tetap pada kedua mata (100%)',
    value: 'Kehilangan pengelihatan total dan tetap pada kedua mata (100%)',
  },
  {
    rate: 0.7,
    label: 'Kehilangan pengelihatan total dan tetap pada satu mata (70%)',
    value: 'Kehilangan pengelihatan total dan tetap pada satu mata (70%)',
  },
  {
    rate: 1,
    label:
      'Kehilangan atau kerusakan fungsi pada dua anggota badan secara total dan tetap (100%)',
    value:
      'Kehilangan atau kerusakan fungsi pada dua anggota badan secara total dan tetap (100%)',
  },
  {
    rate: 0.7,
    value:
      'Kehilangan atau kerusakan fungsi pada satu anggota badan secara total dan tetap (70%)',
    label:
      'Kehilangan atau kerusakan fungsi pada satu anggota badan secara total dan tetap (70%)',
  },
  {
    rate: 1,
    label: 'Kehilangan kemampuan berbicara dan pendengaran (100%)',
    value: 'Kehilangan kemampuan berbicara dan pendengaran (100%)',
  },
  {
    rate: 0.75,
    label:
      'Kehilangan total dan tetap atas pendengaran pada -  kedua telinga (75%)',
    value:
      'Kehilangan total dan tetap atas pendengaran pada -  kedua telinga (75%)',
  },
  {
    rate: 0.25,
    label:
      'Kehilangan total dan tetap atas pendengaran pada -  satu telinga (25%)',
    value:
      'Kehilangan total dan tetap atas pendengaran pada -  satu telinga (25%)',
  },
  {
    rate: 0.5,
    label: 'Kehilangan kemampuan berbicara (50%)',
    value: 'Kehilangan kemampuan berbicara (50%)',
  },
  {
    rate: 0.5,
    label: 'Kehilangan total dan tetap satu lensa mata (50%)',
    value: 'Kehilangan total dan tetap satu lensa mata (50%)',
  },
  {
    rate: 0.6,
    label:
      'Kehilangan atau kehilangan fungsi 4 jari dan ibu jari secara total dan tetap (60%)',
    value:
      'Kehilangan atau kehilangan fungsi 4 jari dan ibu jari secara total dan tetap (60%)',
  },
  {
    rate: 0.4,
    label:
      'Kehilangan atau kehilangan fungsi 4 jari tangan secara total dan tetap (40%)',
    value:
      'Kehilangan atau kehilangan fungsi 4 jari tangan secara total dan tetap (40%)',
  },
  {
    rate: 0.2,
    label:
      'Kehilangan atau kehilangan fungsi satu ibu jari secara total dan tetap - dua ruas (20%)',
    value:
      'Kehilangan atau kehilangan fungsi satu ibu jari secara total dan tetap - dua ruas (20%)',
  },
  {
    rate: 0.15,
    label:
      'Kehilangan atau kehilangan fungsi satu ibu jari secara total dan tetap - satu ruas (15%)',
    value:
      'Kehilangan atau kehilangan fungsi satu ibu jari secara total dan tetap - satu ruas (15%)',
  },
  {
    rate: 0.1,
    label:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Tiga ruas (10%)',
    value:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Tiga ruas (10%)',
  },
  {
    rate: 0.075,
    label:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Dua ruas (7.5%)',
    value:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Dua ruas (7.5%)',
  },
  {
    rate: 0.05,
    label:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Satu ruas (5%)',
    value:
      'Kehilangan atau kehilangan fungsi jari tangan secara total dan tetap - Satu ruas (5%)',
  },
  {
    rate: 0.15,
    label:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Semua jari pada satu kaki (15%)',
    value:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Semua jari pada satu kaki (15%)',
  },
  {
    rate: 0.05,
    label:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Ibu jari, dua ruas (5%)',
    value:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Ibu jari, dua ruas (5%)',
  },
  {
    rate: 0.03,
    label:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Ibu jari, satu ruas (3%)',
    value:
      'Kehilangan atau kehilangan fungsi jari kaki secara total dan tetap - Ibu jari, satu ruas (3%)',
  },
  {
    rate: 0.1,
    label: 'Patah kaki atau lepas dari tempurung kaki (10%)',
    value: 'Patah kaki atau lepas dari tempurung kaki (10%)',
  },
  {
    rate: 0.075,
    label: 'Kaki memendek sedikitnya 5 cm (7.5%)',
    value: 'Kaki memendek sedikitnya 5 cm (7.5%)',
  },
];

export const PROVINCES = [
  { label: 'Aceh', value: 'Aceh' },
  { label: 'Bali', value: 'Bali' },
  { label: 'Banten', value: 'Banten' },
  { label: 'Bengkulu', value: 'Bengkulu' },
  { label: 'D.I Yogyakarta', value: 'D.I Yogyakarta' },
  { label: 'D.K.I Jakarta', value: 'D.K.I Jakarta' },
  { label: 'Gorontalo', value: 'Gorontalo' },
  { label: 'Jambi', value: 'Jambi' },
  { label: 'Jawa Barat', value: 'Jawa Barat' },
  { label: 'Jawa Tengah', value: 'Jawa Tengah' },
  { label: 'Jawa Timur', value: 'Jawa Timur' },
  { label: 'Kalimantan Barat', value: 'Kalimantan Barat' },
  { label: 'Kalimantan Selatan', value: 'Kalimantan Selatan' },
  { label: 'Kalimantan Tengah', value: 'Kalimantan Tengah' },
  { label: 'Kalimantan Timur', value: 'Kalimantan Timur' },
  { label: 'Kalimantan Utara', value: 'Kalimantan Utara' },
  {
    label: 'Kepulauan Bangka Belitung',
    value: 'Kepulauan Bangka Belitung',
  },
  { label: 'Kepulauan Riau', value: 'Kepulauan Riau' },
  { label: 'Lampung', value: 'Lampung' },
  { label: 'Maluku', value: 'Maluku' },
  { label: 'Maluku Utara', value: 'Maluku Utara' },
  { label: 'Nusa Tenggara Barat', value: 'Nusa Tenggara Barat' },
  { label: 'Nusa Tenggara Timur', value: 'Nusa Tenggara Timur' },
  { label: 'Papua', value: 'Papua' },
  { label: 'Papua Barat', value: 'Papua Barat' },
  { label: 'Riau', value: 'Riau' },
  { label: 'Sulawesi Barat', value: 'Sulawesi Barat' },
  { label: 'Sulawesi Selatan', value: 'Sulawesi Selatan' },
  { label: 'Sulawesi Tengah', value: 'Sulawesi Tengah' },
  { label: 'Sulawesi Tenggara', value: 'Sulawesi Tenggara' },
  { label: 'Sulawesi Utara', value: 'Sulawesi Utara' },
  { label: 'Sumatera Barat', value: 'Sumatera Barat' },
  { label: 'Sumatera Selatan', value: 'Sumatera Selatan' },
  { label: 'Sumatera Utara', value: 'Sumatera Utara' },
];

const ACCIDENT_DEATH_CLAIM_AMOUNT = 25000000;
const MEDICAL_EXPENSES_CLAIM_AMOUNT = 2500000;
const ACCIDENTAL_DEATH_MEDICAL_EXPENSES_CLAIM_AMOUNT = 27500000;

const ACCIDENT_DAY_HAPPENED_LIMIT = 7;

const ACCIDENTAL_DEATH = 'Accidental Death';
const MEDICAL_EXPENSES = 'Medical Expenses';
const PERMANENT_DISABLEMENT = 'Permanent Disablement';
const ACCIDENTAL_DEATH_MEDICAL_EXPENSES = 'Accidental Death + Medical Expenses';
const PERMANENT_DISABLEMENT_MEDICAL_EXPENSES =
  'Permanent Disablement + Medical Expenses';

export {
  CLAIMED_BENEFIT,
  ACCIDENT_DAY_HAPPENED_LIMIT,
  ACCIDENT_DEATH_CLAIM_AMOUNT,
  MEDICAL_EXPENSES_CLAIM_AMOUNT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES_CLAIM_AMOUNT,
  ACCIDENTAL_DEATH,
  MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
};
