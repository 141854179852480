import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Select as MuiSelect,
  MenuItem,
  Typography,
  FormHelperText,
} from '@material-ui/core';

import { ReactComponent as ArrowDownIcon } from './arrowdown.svg';
import { ReactComponent as EmptyIcon } from './empty.svg';
import { ReactComponent as CheckedIcon } from './checked.svg';

const useStyles = makeStyles(() => ({
  menu: {
    marginTop: 16,
    '& div.MuiBox-root': {
      overflow: 'hidden',
    },
  },
  input: {
    whiteSpace: 'normal',
  },
}));

function Select({
  fullWidth,
  options,
  name,
  errors,
  placeholder,
  multiple,
  disabled,
  value,
  onChange,
  helperText,
  required,
}) {
  const classes = useStyles();
  return (
    <>
      <MuiSelect
        className={classes.select}
        fullWidth={fullWidth}
        variant="outlined"
        multiple={multiple}
        disabled={disabled}
        value={multiple ? value || [] : value}
        displayEmpty
        onChange={onChange}
        error={!!errors[name]}
        IconComponent={disabled ? 'span' : ArrowDownIcon}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          className: classes.menu,
        }}
        renderValue={e => {
          if (e.length === 0)
            return (
              <Box color="disabled.dark">
                <Typography
                  variant="subtitle1"
                  color={disabled ? 'inherit' : 'textSecondary'}
                >
                  {placeholder}
                </Typography>
              </Box>
            );

          if (typeof e === 'string') {
            const { label } = options.find(({ value: val }) => val === e) || {};
            return label;
          }

          return e.map((item, index) => {
            const selected = options.find(option => {
              const { value: val } = option || {};
              return val === item;
            });
            const { label: itemLabel } = selected || {};
            return (
              <Box component="span" key={item}>
                {itemLabel}
                {index !== e.length - 1 && ', '}
              </Box>
            );
          });
        }}
      >
        {!required && !multiple && (
          <MenuItem value="">
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              color="disabled.dark"
            >
              <Typography
                variant="subtitle1"
                color={disabled ? 'inherit' : 'textPrimary'}
              >
                {placeholder}
              </Typography>
            </Box>
          </MenuItem>
        )}
        {options.map(({ value: val, label }) => (
          <MenuItem key={val} value={val}>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              color="disabled.dark"
              overflow="scroll"
            >
              <Typography
                variant="subtitle1"
                color={disabled ? 'inherit' : 'textPrimary'}
              >
                {label}
              </Typography>
              {multiple &&
                ((value || []).find(item => item === val) ? (
                  <CheckedIcon />
                ) : (
                  <EmptyIcon />
                ))}
            </Box>
          </MenuItem>
        ))}
      </MuiSelect>
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}

Select.propTypes = {
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  name: PropTypes.string,
  errors: PropTypes.object,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
};

Select.defaultProps = {
  fullWidth: true,
  options: [],
  name: '',
  errors: {},
  placeholder: <Trans>- Select -</Trans>,
  multiple: false,
  value: '',
  disabled: false,
  helperText: '',
  required: false,
};

export default Select;
