import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useSearchParam } from 'react-use';
import RRPropTypes from 'react-router-prop-types';
import { makeStyles, withStyles, fade } from '@material-ui/core/styles';
import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TextField,
  InputLabel,
  FormControl,
  TableContainer,
} from '@material-ui/core';

import api from '@platform/api';
import { telkomselFontFamily } from 'variables';
import MessageBar from 'components/MessageBar';
import LinearLoadingBar from 'components/LinearLoadingBar';

import Contact from '../components/Contact';
import BottomIcon from '../components/BottomIcon';
import Typography from '../components/AllianzTypography';

import allianzTelkomselIcon from '../img/allianz-telkomsel.svg';

function BenefitTable() {
  const customizeStyles = makeStyles(theme => ({
    specialWidth: {
      width: '60%',
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'center',
      fontFamily: telkomselFontFamily,
      backgroundColor: '#006192',
      border: '1px solid #003781',
      color: theme.palette.common.white,
    },
    head: {
      width: '40%',
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'center',
      backgroundColor: '#006192',
      border: '1px solid #003781',
      fontFamily: telkomselFontFamily,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      border: '1px solid #003781',
      fontFamily: telkomselFontFamily,
    },
  }))();

  const rows = [
    {
      benefits: <Trans>Death due to accident</Trans>,
      value: 'Rp 25.000.000',
      key: 1,
    },
    {
      benefits: (
        <Trans>
          Permanent Disablement due to Accident (Total/Partial as per benefit
          table)
        </Trans>
      ),
      value: 'Rp 25.000.000',
      key: 2,
    },
    {
      benefits: <Trans>Medical expenses due to accident</Trans>,
      value: 'Rp 25.000.000',
      key: 3,
    },
    {
      benefits: <Trans>Compensation due to accident</Trans>,
      value: 'Rp 200.000',
      key: 4,
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              classes={{
                head: customizeStyles.specialWidth,
              }}
            >
              <Trans>Benefits</Trans>
            </TableCell>
            <TableCell
              classes={{
                head: customizeStyles.head,
              }}
            >
              <Trans>The value of coverage</Trans>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.key}>
              <TableCell
                classes={{
                  body: customizeStyles.body,
                }}
              >
                {row.benefits}
              </TableCell>
              <TableCell
                classes={{
                  body: customizeStyles.body,
                }}
              >
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CodeInput = withStyles(theme => ({
  root: {
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextField);

const useStyle = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(5),
    height: 170,
  },
  formControl: {
    width: '100%',
    height: 20,
  },
  submit: {
    backgroundColor: '#006192',
    color: 'white',
    fontWeight: 600,
    fontFamily: telkomselFontFamily,
    '&:hover': {
      background: '#006192',
    },
  },
  bolder: {
    fontWeight: 600,
  },
}));

const SpreadText = ({ title1, title2 }) => {
  const styles = makeStyles(() => ({
    title1: {
      marginRight: 8,
      fontSize: 14,
      fontWeight: 600,
    },
  }))();
  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" mt={4}>
      <Typography variant="subtitle2" className={styles.title1}>
        {title1}
      </Typography>
      <Typography variant="subtitle2">{title2}</Typography>
    </Box>
  );
};

SpreadText.propTypes = {
  title1: PropTypes.node.isRequired,
  title2: PropTypes.node.isRequired,
};

const InputCode = ({ history }) => {
  const styles = useStyle();
  const activationId = useSearchParam('aid');
  const [voucherCode, setVoucherCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const handleChange = e => {
    setVoucherCode(get(e, 'target.value', ''));
  };

  const handleSubmit = async () => {
    if (!voucherCode) {
      setApiError(<Trans>Kode voucher tidak boleh kosong.</Trans>);
      return;
    }
    setLoading(true);
    try {
      const { data } = await api.verifyVoucherCode(voucherCode, activationId);
      if (data && data.valid_status === 'success') {
        history.push(`/activation?aid=${activationId}`);
      } else if (data && data.valid_status === 'fail') {
        history.push('/activation/error-code');
      }
    } catch (e) {
      setApiError(
        <Trans>
          Something went wrong, please contact our customer service.
        </Trans>
      );
    }
    setLoading(false);
  };
  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Box display="flex" justifyContent="center">
        <img src={allianzTelkomselIcon} alt="allianz-telkomsel" />
      </Box>
      <Box display="flex" justifyContent="center" flexDirection="column">
        <form className={styles.form}>
          <FormControl className={styles.formControl}>
            <InputLabel shrink htmlFor="input-code">
              <Typography variant="h4">
                <Trans>Please enter your voucher code</Trans>
              </Typography>
            </InputLabel>
          </FormControl>
          <CodeInput
            fullWidth
            id="input-code"
            variant="outlined"
            value={voucherCode}
            onChange={handleChange}
          />
          <Box marginTop={3} width="100%">
            <Button
              fullWidth
              variant="contained"
              className={styles.submit}
              onClick={handleSubmit}
            >
              <Trans>Submit</Trans>
            </Button>
          </Box>
        </form>
      </Box>
      <Box>
        <Typography variant="subtitle2" className={styles.bolder}>
          <Trans>
            Enjoy the benefits of Allianz Insurance from Telkomsel Data Package:
          </Trans>
        </Typography>
        <Box
          width="100%"
          marginTop={4}
          display="flex"
          boxSizing="border-box"
          flexDirection="column"
        >
          <Box mb={4}>
            <Typography variant="h5" className={styles.bolder}>
              <Trans>Benefit:</Trans>
            </Typography>
          </Box>
          <BenefitTable />
          <SpreadText
            title1={<Trans>Insured Age: </Trans>}
            title2={<Trans>minimum age 17 years old</Trans>}
          />
          <SpreadText
            title1={<Trans>Period of Cover: </Trans>}
            title2={<Trans>30 days</Trans>}
          />
        </Box>
      </Box>
      <Contact />
      <BottomIcon />
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </>
  );
};

InputCode.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default InputCode;
