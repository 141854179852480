import AllianzNeo from './fonts/AllianzNeo-Regular.ttf';

const allianzNeo = {
  fontFamily: 'AllianzNeo',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('AllianzNeo'),
    local('AllianzNeo-Regular'),
    url(${AllianzNeo}) ('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const palette = {
  primary: {
    light: '#ccccff',
    main: '#6c6cff',
    dark: '#5858ff',
    contrastText: '#f6f6ff',
    white: '#ffffff',
    btn: ' rgba(249, 249, 249, 0.78);',
  },
  secondary: {
    light: '#a6adba', // mid grey
    main: '#6b778c', // dark grey
    dark: '#1f1f1f', // black
  },
  error: {
    light: '#fdf4f2',
    main: '#f52209',
    dark: '#de3618',
  },
  warning: {
    light: '#fff9ed',
    main: '#ffa502',
    dark: '#cf8909',
  },
  expired: {
    light: '#E6FEFE',
    main: '#1A4E80',
    dark: '#cf8909',
  },
  info: {
    light: '#e6fefe',
    main: '#1e90ff',
    dark: '#1a4e80',
  },
  success: {
    light: '#eeffea',
    main: '#2ed573',
    dark: '#108043',
  },
  disabled: {
    light: '#f4f5f7', // lighter grey
    main: '#ebecf0', // light grey
    dark: '#c4cdd5', // grey
  },
  background: {
    main: '#f9fafb', // white grey
    paper: '#ffffff',
  },
  accent: {
    light: '#ffcccc',
    main: '#ff6652',
    dark: '#f34f3a',
  },
};

const Colors = {
  primaryMain: '#009aff',
  primaryLight: '#33aeff',
  primaryDark: '#006bb2',
  secondaryMain: '#ffa502',
  secondaryLight: '#ffb734',
  secondaryDark: '#b27301',
  white: '#ffffff',
  transparent: 'rgba(0,0,0,0)',
  lightLime: '#E6FEFE',
  grey: '#637381',
  lightGrey: '#f8f8f8',
  darkGrey: '#7f96a3',
  watermelon: '#ff4757',
  green: '#43a047',
  lightSteelBlue: '#6b778c',
  orange: '#fa6400',
  darkBlue: '#091e42',
  dark: '#212b36',
  lightDark: '#24313e',
  lightBlue: '#e8f4fa',
  sandYellow: '#fff4cf',
  litchiRed: '#ffe7e7',
  algaeGreen: '#2ed573',
  orangeYellow: '#ffa502',
  dimGray: '#96a0ae',
  athensGray: '#f4f5f7',
  paleYellow: '#fff2d9',
  paleGreen: '#e1faeb',
  paleRed: '#ffecee',
};

const fontFamily = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const telkomselFontFamily = `AllianzNeo, ${fontFamily}`;

const spacing = 4;

export {
  Colors,
  palette,
  spacing,
  fontFamily,
  allianzNeo,
  telkomselFontFamily,
};
