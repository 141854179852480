import locale from 'locale';
import { setupI18n } from '@lingui/core';

import en from './en/messages';
import id from './id/messages';

export const catalogs = {
  en,
  id,
};

function getLanguage() {
  const supported = new locale.Locales(Object.keys(catalogs), 'en');

  // for telkomsel, they need to only use Bahasa(id)
  const browserLanguage = 'id';
  // const browserLanguage =
  //   navigator && (navigator.language || navigator.userLanguage);
  return new locale.Locales(browserLanguage).best(supported).code;
}

export const language = getLanguage();

const i18n = setupI18n({
  language,
  catalogs,
});

export default i18n;
