import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, FormHelperText } from '@material-ui/core';
import RcTimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';

import { makeStyles } from '@material-ui/styles';
import { ReactComponent as TimePickerIcon } from './timepicker.svg';
import { Colors, palette } from '../../../variables';

const useStyles = makeStyles(() => ({
  rcTimePicker: {
    fontSize: 12,
    color: Colors.grey,
    display: 'flex',
    alignItems: 'center',
    border: `1px solid #ebecf0`,
    borderRadius: 4,
    marginBottom: 8,
    marginTop: 8,
    '&>input': {
      border: 'unset',
      height: 44,
      fontSize: 16,
      paddingLeft: 10,
      '&:focus': {
        border: 'unset',
        outline: 'unset',
      },
    },
    '&>svg': {
      marginLeft: -40,
      zIndex: 1,
      pointerEvents: 'auto',
    },
    '&>.rc-time-picker-clear': {
      display: 'none',
    },
  },
  error: {
    border: `1px solid #f52209`,
  },
  popup: {
    paddingTop: 48,
    maxWidth: '100%',
    '& .rc-time-picker-panel-select': {
      width: 80,
      fontSize: 14,
      maxHeight: 350,
      '& li': {
        height: 30,
        lineHeight: '30px',
      },
    },
    '& .rc-time-picker-panel-input': {
      fontSize: 14,
    },
  },
}));

function TimePicker({
  value,
  name,
  errors,
  onChange,
  disabled,
  placeholder,
  format,
  hideDisabledOptions,
  disabledHours,
  disabledMinutes,
  minuteStep,
  helperText,
}) {
  const classes = useStyles();
  function handleChange(val) {
    onChange(val.format(format));
  }
  return (
    <>
      <RcTimePicker
        disabled={disabled}
        showSecond={false}
        className={classes.rcTimePicker}
        style={{
          borderColor: errors[name]
            ? palette.error.main
            : palette.disabled.main,
          WebkitTextFillColor: value ? '' : '#d9d9d9',
        }}
        disabledHours={disabledHours}
        disabledMinutes={disabledMinutes}
        hideDisabledOptions={hideDisabledOptions}
        format={format}
        inputReadOnly
        onChange={handleChange}
        inputIcon={<SvgIcon component={TimePickerIcon} />}
        clearIcon={undefined}
        popupClassName={classes.popup}
        value={value ? moment(value, format) : null}
        placeholder={placeholder}
        minuteStep={minuteStep}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

TimePicker.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  hideDisabledOptions: PropTypes.bool,
  name: PropTypes.string,
  format: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  minuteStep: PropTypes.number,
};

TimePicker.defaultProps = {
  value: '',
  name: '',
  placeholder: '',
  errors: {},
  disabled: false,
  hideDisabledOptions: true,
  disabledHours: () => [],
  disabledMinutes: () => [],
  format: 'HH:mm',
  helperText: '',
  minuteStep: 1,
};

export default TimePicker;
