import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Divider, Typography, Grid, Container } from '@material-ui/core';

import { Colors } from 'variables';
import phoneFormatter from 'utils/phoneFormatter';

import Profile from './profile@3x.png';

const userStyles = makeStyles(theme => ({
  container: {
    padding: '16px 8px',
  },
  detailsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  userDetailContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
  title: {
    fontSize: 14,
    color: Colors.grey,
  },
  img: { width: 24, height: 24 },
  email: { fontSize: 20, marginLeft: 8 },
  spanColor: { color: '#212b36' },
}));

function UserInfo({ user }) {
  const classes = userStyles();
  const { emailAddress, fullName, ktpNumber, mobileNumber } = user;

  return (
    <>
      <Container>
        <Grid container className={classes.container} justify="space-between">
          <Grid item xs={12} sm="auto">
            <div>
              <img alt="profile" src={Profile} className={classes.img} />
              <Typography display="inline" className={classes.email}>
                {emailAddress}
              </Typography>
            </div>
            <div className={classes.detailsContainer}>
              <div className={classes.userDetailContainer}>
                <Typography display="inline" className={classes.title}>
                  <Trans>Insured Person:</Trans>
                  <span className={classes.spanColor}>{fullName}</span>
                </Typography>
              </div>
              {ktpNumber && (
                <div className={classes.userDetailContainer}>
                  <Typography display="inline" className={classes.title}>
                    <Trans>KTP:</Trans>
                    <span className={classes.spanColor}>{ktpNumber}</span>
                  </Typography>
                </div>
              )}
              {mobileNumber && (
                <div className={classes.userDetailContainer}>
                  <Typography display="inline" className={classes.title}>
                    <Trans>Phone Number:</Trans>
                    <span className={classes.spanColor}>
                      {phoneFormatter(mobileNumber)}
                    </span>
                  </Typography>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Divider />
    </>
  );
}

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withRouter(UserInfo);
