import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const VerticalCenterLayout = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      {children}
    </Box>
  );
};

VerticalCenterLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VerticalCenterLayout;
