import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormHelperText,
  FormGroup,
  Typography,
  Grid,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import get from 'lodash/get';
import { ReactComponent as CheckedIcon } from './checked.svg';
import { ReactComponent as EmptyIcon } from '../Checkbox/empty.svg';

const useStyles = makeStyles(() => ({
  menu: {
    background: '#fff',
    borderColor: '#ebecf0',
    height: '100%',
  },
}));

function CheckboxGroup({
  value = [],
  onChange,
  name,
  errors,
  disabled,
  helperText,
  options,
}) {
  const classes = useStyles();
  const handleChange = eventOrValue => {
    const checkedValue = get(eventOrValue, 'target.value', eventOrValue);
    const checked = get(eventOrValue, 'target.checked', eventOrValue);
    onChange({ value: checkedValue, checked });
  };
  return (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <FormGroup style={{ width: '100%' }}>
          <Grid container spacing={2}>
            {options.map(item => {
              const isDisabled = disabled || item.disabled;
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.value}
                  style={{ marginBottom: 12 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    color="disabled.dark"
                    border={1}
                    borderRadius={4}
                    pl={4}
                    pr={2}
                    py={1}
                    className={classes.menu}
                  >
                    <Typography
                      variant="subtitle1"
                      color={isDisabled ? 'inherit' : 'textPrimary'}
                    >
                      {item.label}
                    </Typography>
                    <MuiCheckbox
                      icon={<EmptyIcon />}
                      checkedIcon={<CheckedIcon />}
                      checked={value.includes(item.value)}
                      value={item.value}
                      onChange={handleChange}
                      disabled={isDisabled}
                      name="gilad"
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

CheckboxGroup.propTypes = {
  value: PropTypes.array,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
};

CheckboxGroup.defaultProps = {
  value: [],
  disabled: false,
  onChange: () => {},
  errors: {},
  name: '',
  helperText: '',
};

export default CheckboxGroup;
