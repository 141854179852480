import { configureStore } from '@reduxjs/toolkit';

import global from 'app/globalSlice';
import user from 'app/userSlice';

import slices from '@platform/store';

const store = configureStore({
  reducer: {
    global,
    user,
    ...slices,
  },
});

export default store;
