import apisauce from 'apisauce';

import { getJWT } from 'utils/jwt';

import fixtures from './fixtures';

const create = (baseURL = 'http://localhost:4000') => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      'X-Axinan-User-Token': getJWT('TELKOMSEL') || '',
    },
    // 1 minute timeout...
    timeout: 60000,
  });

  api.addResponseTransform(response => {
    if (!response.ok) {
      const error = new Error(response.status || response.problem);

      error.status = response.status;
      error.res = response;

      throw error;
    }
  });

  const subpath = baseURL !== 'http://localhost:4000' ? '/v1/pa' : '';

  const appendHeaders = headers => {
    const newHeaders = {
      ...headers,
    };
    api.setHeaders(newHeaders);
  };

  const verifyVoucherCode = (voucherCode, activationId) =>
    api.get(
      `${subpath}/verify_voucher_code?code=${voucherCode}&activation_id=${activationId}`
    );

  const activateActivation = (data, activationId) =>
    api.put(`${subpath}/activations/${activationId}/upload_form`, data);

  const confirmActivation = activationId =>
    api.put(`${subpath}/activations/${activationId}/confirm`);

  const getPolicies = () => api.get(`${subpath}/customer_portal/policies`);

  const getToken = (secret, userId) =>
    api.get(`${subpath}/order_token?secret=${secret}&user_id=${userId}`);

  const getUrlsToUpload = type =>
    api.get(`${subpath}/customer_portal/upload_file_url?suffix=${type}`);

  const uploadFile = async (url, file) => {
    return fetch(url, {
      method: 'PUT',
      body: file,
    }).then(res => res && res.url);
  };

  const updateFiles = (policyId, claimId, files) =>
    api.put(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/upload_file`,
      files
    );

  const updateClaimReason = (policyId, claimId, reason) =>
    api.post(
      `${subpath}/customer_portal/policies/${policyId}/claims/upload_claim_reason?claim_id=${claimId}`,
      {
        claim_reason: reason,
      }
    );

  const updateDetails = (policyId, claimId, details) =>
    api.put(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/upload_detail`,
      details
    );

  const cancelClaim = (policyId, claimId) =>
    api.put(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/cancel`
    );

  const getReason = (policyId, claimId) =>
    api.get(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}`
    );

  const reimburse = (policyId, claimId, bank) =>
    api.post(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/reimburse/upload`,
      bank
    );

  const cancelReimburse = (policyId, claimId, reimburseId) =>
    api.put(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/reimburse/${reimburseId}/cancel`
    );

  const submitClaim = (policyId, claimId) =>
    api.put(
      `${subpath}/customer_portal/policies/${policyId}/claims/${claimId}/submit`
    );

  return {
    getToken,
    reimburse,
    getReason,
    uploadFile,
    cancelClaim,
    submitClaim,
    updateFiles,
    getPolicies,
    updateDetails,
    appendHeaders,
    getUrlsToUpload,
    cancelReimburse,
    verifyVoucherCode,
    updateClaimReason,
    confirmActivation,
    activateActivation,
  };
};

export default process.env.REACT_APP_ENV === 'fixture'
  ? fixtures
  : create(process.env.REACT_APP_TELKOMSEL_API_URL);
