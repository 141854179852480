import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Box,
  Table,
  Dialog,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from '@material-ui/core';
import { Trans } from '@lingui/macro';

import { telkomselFontFamily } from 'variables';

import Typography from './AllianzTypography';

const useStyle = makeStyles(({ breakpoints }) => ({
  confirm: {
    background: '#006192',
    color: 'white',
    '&:hover': {
      background: '#006192',
    },
  },
  paper: {
    flex: 1,
    margin: '0',
    height: '80%',
    display: 'flex',
    borderRadius: '20px 20px 0 0',
    [breakpoints.up('md')]: {
      maxWidth: '400px',
    },
  },
  scrollPaper: {
    alignItems: 'flex-end',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  link: {
    color: 'rgb(38, 97, 142)',
  },
  bolder: {
    fontWeight: 600,
  },
}));

export const TextSlide = ({ children }) => {
  return (
    <Box my={3}>
      <Typography variant="subtitle2">{children}</Typography>
    </Box>
  );
};
TextSlide.propTypes = {
  children: PropTypes.node.isRequired,
};

function DisabilityTable() {
  const StyledTableCell = withStyles(() => ({
    head: {
      width: '50%',
      fontFamily: telkomselFontFamily,
      textAlign: 'center',
    },
    body: {
      fontSize: 12,
      fontWeight: 600,
      textAlign: 'center',
      borderBottom: 'none',
      fontFamily: telkomselFontFamily,
    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      fontFamily: telkomselFontFamily,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const rows = [
    {
      label: (
        <Trans>Kehilangan penglihatan total dan tetap pada kedua mata</Trans>
      ),
      value: '100%',
      key: 1,
    },
    {
      label: (
        <Trans>Kehilangan penglihatan total dan tetap pada satu mata</Trans>
      ),
      value: '70%',
      key: 2,
    },
    {
      label: (
        <Trans>
          Kehilangan atau kerusakan fungsi pada dua anggota badan secara total
          dan tetap
        </Trans>
      ),
      value: '100%',
      key: 3,
    },
    {
      label: (
        <Trans>
          Kehilangan atau kerusakan fungsi pada satu anggota badan secara total
          dan tetap
        </Trans>
      ),
      value: '70%',
      key: 4,
    },
    {
      label: <Trans>Kehilangan kemampuan berbicara dan pendengaran</Trans>,
      value: '100%',
      key: 5,
    },
    {
      label: <Trans>Kehilangan total dan tetap atas pendengaran pada</Trans>,
      value: '',
      key: 6,
    },
    {
      label: <Trans>- kedua telinga</Trans>,
      value: '75%',
      key: 7,
    },
    {
      label: <Trans>- satu telinga</Trans>,
      value: '25%',
      key: 8,
    },
    {
      label: <Trans>Kehilangan kemampuan berbicara</Trans>,
      value: '50%',
      key: 9,
    },
    {
      label: <Trans>Kehilangan total dan tetap satu lensa mata</Trans>,
      value: '50%',
      key: 10,
    },
    {
      label: (
        <Trans>
          Kehilangan fungsi 4 jari dan ibu jari secara total dan tetap
        </Trans>
      ),
      value: '60%',
      key: 11,
    },
    {
      label: (
        <Trans>Kehilangan fungsi 4 jari tangan secara total dan tetap</Trans>
      ),
      value: '40%',
      key: 12,
    },
    {
      label: (
        <Trans>Kehilangan fungsi satu ibu jari secara total dan tetap</Trans>
      ),
      value: '',
      key: 13,
    },
    {
      label: <Trans>- dua ruas</Trans>,
      value: '20%',
      key: 14,
    },
    {
      label: <Trans>- satu ruas</Trans>,
      value: '15%',
      key: 15,
    },
    {
      label: (
        <Trans>Kehilangan fungsi jari tangan secara total dan tetap</Trans>
      ),
      value: '',
      key: 16,
    },
    {
      label: <Trans>- Tiga ruas</Trans>,
      value: '10%',
      key: 17,
    },
    {
      label: <Trans>- Dua ruas</Trans>,
      value: '7.5%',
      key: 18,
    },
    {
      label: <Trans>- Satu ruas</Trans>,
      value: '5%',
      key: 19,
    },
    {
      label: <Trans>Kehilangan fungsi jari kaki secara total dan tetap</Trans>,
      value: '',
      key: 20,
    },
    {
      label: <Trans>- Semua jari pada satu kaki</Trans>,
      value: '15%',
      key: 21,
    },
    {
      label: <Trans>- Ibu jari, dua ruas</Trans>,
      value: '5%',
      key: 22,
    },
    {
      label: <Trans>- Ibu jari, satu ruas</Trans>,
      value: '3%',
      key: 23,
    },
    {
      label: <Trans>Patah kaki atau lepas dari tempurung kaki</Trans>,
      value: '10%',
      key: 24,
    },
    {
      label: <Trans>Kaki memendek sedikitnya 5 cm</Trans>,
      value: '7.5%',
      key: 25,
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.key}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell>{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ProductTable() {
  const StyledTableCell = withStyles(theme => ({
    head: {
      width: '50%',
      fontSize: 14,
      fontWeight: 600,
      textAlign: 'center',
      backgroundColor: '#003781',
      fontFamily: telkomselFontFamily,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 12,
      fontWeight: 600,
      textAlign: 'center',
      borderBottom: 'none',
      fontFamily: telkomselFontFamily,
    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      fontFamily: telkomselFontFamily,
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const rows = [
    {
      benefits: <Trans>Meninggal Dunia Akibat Kecelakaan</Trans>,
      value: 'Rp 25.000.000',
      key: 1,
    },
    {
      benefits: (
        <Trans>
          Cacat Tetap Akibat Kecelakaan (Total/Sebagian Mengacu Pada Tabel
          Manfaat)
        </Trans>
      ),
      value: 'Rp 25.000.000',
      key: 2,
    },
    {
      benefits: <Trans>Biaya Pengobatan Akibat Kecelakaan</Trans>,
      value: 'Rp 2.500.000',
      key: 3,
    },
    {
      benefits: <Trans>Compensation due to accident</Trans>,
      value: 'Rp 200.000',
      key: 4,
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Trans>Manfaat</Trans>
            </StyledTableCell>
            <StyledTableCell>
              <Trans>Jumlah Penggantian Maksimal</Trans>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.key}>
              <StyledTableCell component="th" scope="row">
                {row.benefits}
              </StyledTableCell>
              <StyledTableCell>{row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ConfirmModal({ open, closeModal }) {
  const styles = useStyle();
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={closeModal}
      classes={{ paper: styles.paper, scrollPaper: styles.scrollPaper }}
    >
      <Box>
        <Box p={4}>
          <Box display="flex" alignItems="center">
            <Box pt={2} pb={2} textAlign="center" width="90%">
              <Typography variant="h4" className={styles.bolder}>
                <Trans>Terms and Conditions</Trans>
              </Typography>
            </Box>
            <CloseIcon onClick={closeModal} className={styles.closeIcon} />
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box p={4} overflow="scroll">
        <Box my={2}>
          <Typography variant="subtitle2">
            <Trans>Asuransi Kecelakaan Diri – Syarat dan Ketentuan :</Trans>
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="h5" className={styles.bolder}>
            <Trans>Ringkasan Informasi Produk dan Layanan (RIPLAY)</Trans>
          </Typography>
        </Box>
        <ProductTable />
        <TextSlide>
          <Trans>Periode Perlindungan : 30 Hari</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>Batasan Usia : 17 Tahun Keatas</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            Batas Pelaporan Klaim : 14 Hari Kalendar sejak tanggal kejadian
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            Ketentuan tambahan : Hanya berlaku maksimum 2 polis aktif saat
            terjadi klaim
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            Perlindungan atas Paket Data Bundle Asuransi Kecelakaan Diri akan
            dimulai dari saat pelanggan berhasil melakukan proses aktivasi polis
            dimulai jam 12.00 WIB pada tanggal aktivasi pada microsite Allianz.
          </Trans>
        </TextSlide>
        <Box my={2}>
          <Typography variant="subtitle2" className={styles.bolder}>
            <Trans>Tabel Manfaat Cacat Tetap</Trans>
          </Typography>
        </Box>
        <DisabilityTable />
        <Box mt={7} mb={4}>
          <Typography variant="subtitle2" className={styles.bolder}>
            <Trans>PENGECUALIAN :</Trans>
          </Typography>
        </Box>
        <TextSlide>
          <Trans>
            1. Segala peristiwa yang timbul dari perang, invasi, tindakan asing,
            musuh, permusuhan (apakah perang dinyatakan atau tidak), perang
            saudara, pemberontakan pemberontak, kekuatan militer atau kelompok
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            2. Segala kondisi yang sudah ada, bawaan dan kondisi turun-temurun
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            3. Segala tindakan ilegal atau melanggar hukum oleh Tertanggung atau
            penyitaan, penahanan, penghancuran oleh adat atau otoritas lain
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            4. Segala kondisi yang diakibatkan oleh kehamilan, persalinan, atau
            keguguran, aborsi, perawatan pra-natal serta perawatan
            pascakelahiran dan komplikasi lain yang timbul dari penyakitnya
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>5. Melakukan aktifitas berbahaya</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            6. Kegilaan, gangguan mental atau saraf, kondisi apa pun di bawah
            pengaruh alkohol atau obat-obatan (selain yang ditentukan oleh
            praktisi medis yang berkualifikasi) alkoholisme, kecanduan narkoba
            atau penyalahgunaan pelarut
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>7. Reaksi nuklir, radiasi atau kontaminasi radioaktif</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>8. Terorisme Nuklir, Kimia, dan Biologis</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>9. Bunuh diri atau menciderai diri sendiri</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            10. Tertanggung memiliki kadar alkohol yang lebih tinggi secara
            hukumdalam darah
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            11. Tertanggung tidak melakukan semua upaya yang wajar untuk
            menghindari cedera atau untuk meminimalkan klaim
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>12. Balapan dalam bentuk apapun</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>13. Olah raga berbahaya dan atau profesional</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>14. Cacat Sementara</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>15. Biaya Pemakaman</Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            16. Covid-19 dan kerugian langsung maupun tidak langsung akibat
            Penyakit Menular, Pandemi atau Epidemi
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            17. Pengecualian lainnya mengacu kepada wording Polis standard
            Asuransi Kecelakaan Diri yang diterbitkan oleh PT. Asuransi Allianz
            Utama Indonesia
          </Trans>
        </TextSlide>

        <Box mb={4} mt={7}>
          <Typography variant="subtitle2" className={styles.bolder}>
            <Trans>
              Saya, sebagai Calon Tertanggung (“Saya”), telah mengisi informasi
              didalam Formulir Asuransi Kecelakaan Diri ini dengan benar dan
              jujur dan dengan ini pula menyatakan dan menyetujui :
            </Trans>
          </Typography>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle2">
            <Trans>1. Semua keterangan yang diberikan di dalam</Trans>
            <strong>
              {' '}
              <Trans>Formulir Asuransi Kecelakaan Diri</Trans>{' '}
            </strong>
            <Trans>
              ini dan keterangan lain yang Saya sampaikan kepada PT Asuransi
              Allianz Utama Indonesia (selanjutnya disebut “Allianz”) adalah
              benar sudah Saya tuliskan dan/atau cantumkan di dalam
            </Trans>
            <strong>
              {' '}
              <Trans>Formulir Asuransi Kecelakaan Diri</Trans>{' '}
            </strong>
            <Trans>
              (termasuk yang ditulis di dalam Endosemen (jika ada)) dan tidak
              ada keterangan-keterangan dan hal-hal lain yang Saya sembunyikan
              serta Saya sendiri yang menyetujui
            </Trans>
            <strong>
              {' '}
              <Trans>Formulir Asuransi Kecelakaan Diri</Trans>{' '}
            </strong>
            <Trans>ini.</Trans>
          </Typography>
          <TextSlide>
            <Trans>
              2. Saya dengan ini memberi kuasa kepada Allianz untuk:
            </Trans>
          </TextSlide>
          <TextSlide>
            <Box px={3}>
              <Trans>
                a. Mengungkapkan informasi pribadi Saya kepada perusahaan
                eksternal yang telah diperiksa oleh Allianz melalui proses
                penyaringan identifikasi untuk bekerja atas nama Allianz guna
                menyediakan beberapa fungsi terkait produk ini;
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Box px={3}>
              <Trans>
                b. Mengungkapkan informasi pribadi Saya untuk berbagi data
                dengan perusahaan induk Allianz, afiliasi, anak perusahaan,
                agen, usaha patungan dan mitra strategis untuk melakukan fungsi
                bisnis terkait produk ini;
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Box px={3}>
              <Trans>
                c. Mengungkapkan informasi pribadi Saya dalam hal menanggapi
                perintah pengadilan atau proses hukum atau permintaan dari
                regulator manapun atau pihak berwenang atau untuk melindungi
                terhadap penipuan atau aktivitas ilegal lainnya atau untuk
                tujuan manajemen risiko atau untuk memungkinkan Allianz untuk
                melakukan upaya hukum yang tersedia atau membatasi kerusakan
                yang mungkin terjadi terhadap Allianz, dan/atau mematuhi hukum
                dan proses hukum termasuk tetapi tidak terbatas pada peraturan
                tentang FATCA dan CRS (“Kebutuhan Pelaporan Pertukaran Informasi
                Antar Negara”). Saya juga bersedia untuk memberikan
                informasi/dokumen yang diperlukan guna memenuhi kebutuhan
                terkait (“Informasi Terkait”) dan dengan segera menyampaikan
                pengikian data kepada Allianz jika ada perubahan atas Informasi
                Terkait tersebut.
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Trans>
              3. Bahwa apabila ternyata pernyataan, keterangan, dokumen dan
              jawaban yang Saya berikan tidak benar/diragukan kebenarannya, maka
              Saya memahami serta menyetujui sepenuhnya bahwa Polis menjadi
              batal dan Allianz dibebaskan dari segala kewajibannya membayar
              Uang Pertanggungan, segala gugatan, tuntutan, klaim atau bagian
              dari itu dalam bentuk dan nama apapun
            </Trans>
          </TextSlide>
          <TextSlide>
            <Trans>
              4. Pertanggungan asuransi akan mulai berlaku penuh apabila hal-hal
              berikut ini telah terjadii:
            </Trans>
          </TextSlide>
          <TextSlide>
            <Box px={3}>
              <Trans>
                a. Polis atau surat/pernyataan akseptasi telah diterbitkan
                Allianz; dan
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Box px={3}>
              <Trans>
                b. Calon Tertanggung sudah melakukan aktivasi didalam Microsite
                Allianz
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Box color="#E4003A">
              <Trans>
                Saya dengan ini melepaskan ketentuan-ketentuan yang termaktub
                dalam Pasal 1813, 1814, dan 1816 Kitab Undang-Undang Hukum
                Perdata, serta pemberian kuasa ini tidak dapat ditarik kembali
                dan mengikat para pengganti/ahli waris/Penerima Manfaat dan
                orang yang ditunjuk dan tetap berlaku setelah Saya meninggal
                atau dalam keadaan cacat atau berada di bawah pengampuan.
                Salinan (fotokopi) surat kuasa ini adalah sah dan mempunyai
                kekuatan hukum yang sama seperti aslinya.
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Trans>
              5. Kecuali seperti tertera pada angka 4 di atas, Saya menyadari
              sepenuhnya bahwa Allianz memiliki hak untuk menolak/menerima,
              membatalkan/mengubah (dalam hal ini termasuk untuk
              menambahkan/mengurangi) ketentuan dalam perjanjian pertanggungan
              ini, termasuk antara lain untuk menentukan bahwa apabila Calon
              Tertanggung ditolak kepesertaannya oleh Allianz, selain itu.
            </Trans>
          </TextSlide>
          <TextSlide>
            <Box color="#E4003A">
              <Trans>
                6. Saya telah memahami bahwa fasilitas asuransi kecelakaan diri
                ini bukan merupakan produk dan layanan dari PT Telekomunikasi
                Selular (“Telkomsel”). Telkomsel dalam hal ini hanya bertindak
                sebagai Pemegang Polis dan pihak yang melakukan pembayaran atas
                Premi. Untuk itu, Saya sadar dan setuju bahwa Telkomsel tidak
                bertanggung jawab atas setiap klaim, gugatan, tuntutan, risiko
                keberatan, atau tindakan apapun yang timbul sehubungan dengan
                fasilitas asuransi kecelakaan diri ini dan lebih lanjut Saya
                sepakat dengan ini untuk (i) mengesampingkan dan melepaskan
                segala hak yang Saya miliki untuk, mengajukan atau melakukan
                klaim, gugatan, tuntutan, risiko, keberatan, perlawanan, atau
                tindakan apapun terhadap Telkomsel; dan (ii) membebaskan dan
                melindungi Telkomsel sepenuhnya dari segala klaim, gugatan,
                tuntutan, risiko, keberatan, atau tindakan apapun yang diajukan
                atau dilakukan terhadap Telkomsel dan oleh pihak lain manapun
                sehubungan dengan fasilitas asuransi kecelakaan diri.
              </Trans>
            </Box>
          </TextSlide>
          <TextSlide>
            <Trans>
              7. Saya sadar dan sudah mengetahui serta memahami sepenuhnya jenis
              produk asuransi Allianz dan mendapatkan penjelasan atas produk.
            </Trans>
          </TextSlide>
          <TextSlide>
            <Trans>
              8. Saya menyatakan dan menyetujui bahwa Konfirmasi Pertanggungan
              versi elektronik akan dikirimkan melalui e-mail dan SMS.
            </Trans>
          </TextSlide>
          <TextSlide>
            <Trans>
              9. Saya mengerti bahwa untuk mempelajari lebih lanjut tentang
              bagaimana Allianz melindungi data pribadi dan hak-hak Saya dengan
              mengakses tautan berikut:
            </Trans>
            <Box>
              <a
                href="http://www.allianz.co.id/data-privasi/prinsip-privasi"
                target="__blank"
                className={styles.link}
              >
                http://www.allianz.co.id/data-privasi/prinsip-privasi.
              </a>
            </Box>
          </TextSlide>
          <TextSlide>
            <Trans>10. Dengan menandatangani</Trans>
            <strong>
              {' '}
              <Trans>Formulir Asuransi Kecelakaan Diri</Trans>{' '}
            </strong>
            <Trans>
              ini, maka Saya telah menyetujui penyataan dan surat kuasa ini.
            </Trans>
          </TextSlide>
          <Box>
            <Box color="#006192" textAlign="center" mb={3}>
              <Typography variant="body1">
                <Trans>
                  -Asuransi Kecelakaan Diri adalah produk Asuransi yang
                  diterbitkan oleh PT Asuransi Allianz Utama Indonesia dan sudah
                  berizin dan diawasi oleh Otoritas Jasa Keuangan.
                </Trans>
              </Typography>
            </Box>
            <Box color="#006192" textAlign="center" mb={3}>
              <Typography variant="body1">
                <Trans>
                  -PT Asuransi Allianz Utama Indonesia berizin dan diawasi oleh
                  Otoritas Jasa Keuangan.
                </Trans>
              </Typography>
            </Box>
            <Box color="#006192" textAlign="center" mb={3}>
              <Typography variant="body1">
                <Trans>
                  -Penjelasan lebih lengkap mengenai pertanggungan asuransi dan
                  pengecualian dapat Aanda pelajari pada Konfirmasi
                  Pertanggungan.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ConfirmModal;
