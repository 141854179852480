import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useSearchParam, useLocation } from 'react-use';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Radio,
  Container,
  Typography,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
} from '@material-ui/core';

import api from '@platform/api';
import MessageBar from 'components/MessageBar';
import LinearLoadingBar from 'components/LinearLoadingBar';

import { updateClaim } from '../claimSlice';
import Stepper from '../../../components/Stepper';
import ButtonGroup from '../../components/ButtonGroup';

import { ReactComponent as EmptyIcon } from './empty.svg';
import { ReactComponent as CheckedIcon } from './checked.svg';

import {
  ACCIDENTAL_DEATH,
  MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
} from '../constant';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    padding: spacing(4),
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const RadioGroup = ({ value, options, handleChange }) => {
  return (
    <MUIRadioGroup value={value}>
      {options.map(item => {
        return (
          <FormControlLabel
            key={item.value}
            value={item.value}
            onChange={e => handleChange(e.target.value)}
            control={
              <Radio
                value={item.value}
                color="primary"
                icon={<EmptyIcon />}
                checkedIcon={<CheckedIcon />}
              />
            }
            label={<Typography variant="subtitle1">{item.label}</Typography>}
            labelPlacement="end"
          />
        );
      })}
    </MUIRadioGroup>
  );
};
RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
};

const radioOptions = [
  {
    value: MEDICAL_EXPENSES,
    label: <Trans>Medical Expenses</Trans>,
  },
  {
    value: PERMANENT_DISABLEMENT,
    label: <Trans>Permanent Disablement</Trans>,
  },
  {
    value: ACCIDENTAL_DEATH,
    label: <Trans>Accidental Death</Trans>,
  },
  {
    value: PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
    label: <Trans>Permanent Disablement + Medical Expenses</Trans>,
  },
  {
    value: ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
    label: <Trans>Accidental Death + Medical Expenses</Trans>,
  },
];

const Reason = ({ history }) => {
  const styles = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();
  const claimId = useSearchParam('claimId') || '';
  const { reason } = useSelector(state => state.claim);

  const [radio, setRadio] = useState(reason);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    dispatch(updateClaim({ reason: radio }));
    try {
      const policyId = get(location, 'state.state.policyId');
      const res = await api.updateClaimReason(policyId, claimId, radio);
      history.push(`/claim/detail?claimId=${get(res, 'data.claim_id')}`, {
        policyId,
      });
    } catch (e) {
      setApiError(
        <Trans>
          Something went wrong, please contact our customer service.
        </Trans>
      );
    }
    setLoading(false);
  };

  const handleCancel = () => history.push('/');

  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Stepper totalSteps={5} curStep={1} />
      <Container maxWidth="xl">
        <Box className={styles.content}>
          <Typography variant="h1">
            <Trans>What is the reason of this claim?</Trans>
          </Typography>
          <Box color="#6B778C" my={4}>
            <Typography variant="subtitle1">
              <Trans>
                You are only allowed to file a claim for Accidental Death,
                Permanen Disablement and Medical Expenses
              </Trans>
            </Typography>
          </Box>
          <Box bgcolor="#F9FAFB" padding={5} my={6}>
            <RadioGroup
              value={radio}
              handleChange={setRadio}
              options={radioOptions}
            />
          </Box>
          <ButtonGroup
            disabledRight={!radio}
            rightHandle={handleNext}
            leftHandle={handleCancel}
            right={<Trans>Next</Trans>}
            left={<Trans>Cancel</Trans>}
          />
        </Box>
      </Container>
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </>
  );
};
Reason.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default Reason;
