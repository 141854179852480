import React, { useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@material-ui/core';

import Form from 'components/Form';

import { numberWithPoint } from 'utils/number';

import {
  CLAIMED_BENEFIT,
  ACCIDENTAL_DEATH,
  MEDICAL_EXPENSES,
  ACCIDENT_DEATH_CLAIM_AMOUNT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
} from '../constant';

const AccidentDetail = ({ accidentDetails, reason, errors, handleChange }) => {
  const dateRef = useRef(null);
  const timeRef = useRef(null);
  const placeRef = useRef(null);
  const chronologyRef = useRef(null);

  const field = 'accidentDetails';

  const chooseAccidentSelector = () => {
    const claimAmountItem = {
      field,
      type: 'input',
      name: 'claimAmount',
      errors: errors[field],
      startAdornment: 'IDR',
      title: (
        <Trans>Claim Amount (numbers only with no special characters)</Trans>
      ),
      value: numberWithPoint(get(accidentDetails, 'claimAmount', '')),
    };
    const claimedBenefitItem = {
      field,
      type: 'select',
      required: true,
      errors: errors[field],
      name: 'claimedBenefit',
      title: <Trans>Claimed Benefit</Trans>,
      value: get(accidentDetails, 'claimedBenefit'),
    };

    switch (reason) {
      case ACCIDENTAL_DEATH: {
        claimAmountItem.disabled = true;
        claimAmountItem.value = numberWithPoint(ACCIDENT_DEATH_CLAIM_AMOUNT);
        claimedBenefitItem.options = [
          { value: ACCIDENTAL_DEATH, label: <Trans>Accident Death</Trans> },
        ];
        claimedBenefitItem.value = ACCIDENTAL_DEATH;
        claimedBenefitItem.disabled = true;
        claimedBenefitItem.required = false;
        break;
      }
      case MEDICAL_EXPENSES: {
        claimAmountItem.helperText = (
          <Trans>
            The claim amount should not exceed the maximum benefit of IDR
            2.500.000,00
          </Trans>
        );
        claimAmountItem.required = true;
        claimedBenefitItem.options = [
          { value: MEDICAL_EXPENSES, label: <Trans>Medical Expenses</Trans> },
        ];
        claimedBenefitItem.value = MEDICAL_EXPENSES;
        claimedBenefitItem.disabled = true;
        claimedBenefitItem.required = false;
        break;
      }
      case ACCIDENTAL_DEATH_MEDICAL_EXPENSES: {
        claimAmountItem.helperText = (
          <Trans>
            The claim amount should not exceed the maximum benefit of IDR
            27.500.000,00
          </Trans>
        );

        claimAmountItem.required = true;
        claimedBenefitItem.options = [
          {
            value: ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
            label: <Trans>Accidental Death + Medical Expenses</Trans>,
          },
        ];
        claimedBenefitItem.value = ACCIDENTAL_DEATH_MEDICAL_EXPENSES;
        claimedBenefitItem.disabled = true;
        claimedBenefitItem.required = false;
        break;
      }
      case PERMANENT_DISABLEMENT_MEDICAL_EXPENSES: {
        claimAmountItem.helperText = (
          <Trans>
            The claim amount should not exceed the maximum benefit of IDR
            27.500.000,00
          </Trans>
        );

        claimAmountItem.required = true;
        claimedBenefitItem.required = false;
        claimedBenefitItem.options = CLAIMED_BENEFIT;
        claimedBenefitItem.placeholder = <Trans>- Select -</Trans>;
        break;
      }
      default: {
        claimAmountItem.disabled = true;
        claimedBenefitItem.options = CLAIMED_BENEFIT;
        claimedBenefitItem.placeholder = <Trans>- Select -</Trans>;
      }
    }

    return [claimAmountItem, claimedBenefitItem];
  };

  const dateOfAccidentList = [
    {
      field,
      sm: 6,
      type: 'date',
      ref: dateRef,
      required: true,
      name: 'dateOfAccident',
      disableFuture: true,
      errors: errors[field],
      placeholder: 'DD / MM / YYYY',
      title: <Trans>Date of Accident</Trans>,
      value: get(accidentDetails, 'dateOfAccident'),
      helperText: (
        <Trans>
          Please select accident date within policy coverage period.
        </Trans>
      ),
    },
  ];

  const list = [
    {
      field,
      type: 'time',
      ref: timeRef,
      required: true,
      name: 'timeOfAccident',
      errors: errors[field],
      title: <Trans>Time of Accident </Trans>,
      value: get(accidentDetails, 'timeOfAccident'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: placeRef,
      name: 'placeOfAccident',
      errors: errors[field],
      title: <Trans>Place of Accident </Trans>,
      value: get(accidentDetails, 'placeOfAccident'),
    },
    {
      field,
      sm: 12,
      rows: 6,
      type: 'input',
      required: true,
      multiline: true,
      ref: chronologyRef,
      errors: errors[field],
      name: 'chronologyOfAccident',
      title: <Trans>Chronology of Accident (minium 80 characters) </Trans>,
      value: get(accidentDetails, 'chronologyOfAccident'),
    },
    ...chooseAccidentSelector(),
  ];

  return (
    <>
      <Box color="#FF6652" mt={10}>
        <Typography variant="h5">
          <Trans>4. Accident Details</Trans>
        </Typography>
      </Box>
      <Box mt={2} color="#6B778C">
        <Typography variant="subtitle1">
          <Trans>
            Please fill up correct information of your Claims Details
          </Trans>
        </Typography>
      </Box>
      <Box marginY={3} padding={4} bgcolor="#F9FAFB">
        <Form list={dateOfAccidentList} handleChange={handleChange} />
        <Form list={list} handleChange={handleChange} />
      </Box>
    </>
  );
};

AccidentDetail.propTypes = {
  reason: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  accidentDetails: PropTypes.object.isRequired,
};

export default AccidentDetail;
