import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Trans } from '@lingui/macro';

import InfoPaper from './InfoPaper';
import ClaimStatus from '../constants/ClaimStatus';

const failedStatus = [
  ClaimStatus.CLAIM_REJECTED,
  ClaimStatus.REIMBURSEMENT_FAILED,
];

function InfoBox({ state, rejectReason, reimburseState, pendingReason }) {
  if (state === ClaimStatus.CLAIM_EXPIRED) {
    return (
      <InfoPaper type="expired">
        <Trans>
          Missing information was not provided to us within the given deadline.
        </Trans>
      </InfoPaper>
    );
  }

  const isFailedStatus =
    failedStatus.includes(state) || failedStatus.includes(reimburseState);
  if (!isFailedStatus && state !== ClaimStatus.CLAIM_PENDING) return null;
  return (
    <Box mt={4}>
      {isFailedStatus ? (
        <InfoPaper type="error">{rejectReason}</InfoPaper>
      ) : (
        <InfoPaper type="warning">{pendingReason}</InfoPaper>
      )}
    </Box>
  );
}

InfoBox.propTypes = {
  state: PropTypes.string.isRequired,
  rejectReason: PropTypes.string.isRequired,
  pendingReason: PropTypes.string.isRequired,
  reimburseState: PropTypes.string.isRequired,
};

export default InfoBox;
