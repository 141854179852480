import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { decamelizeKeys } from 'humps';
import RRPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParam, useLocation } from 'react-use';
import { Box, Container, Typography } from '@material-ui/core';

import api from '@platform/api';
import MessageBar from 'components/MessageBar';
import Form, { getErrors } from 'components/Form';
import LinearLoadingBar from 'components/LinearLoadingBar';

import Stepper from '../../../components/Stepper';
import ButtonGroup from '../../components/ButtonGroup';

import { updateClaim } from '../claimSlice';

import {
  ACCIDENTAL_DEATH,
  MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
} from '../constant';

const useStyle = makeStyles(({ breakpoints, spacing }) => ({
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    padding: spacing(4),
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  link: {
    overflow: 'hidden',
    width: '100%',
    maxHeight: spacing(4),
  },
}));

const AWSUpload = api.uploadFile;
const getAWSUploadUrls = api.getUrlsToUpload;

const Document = ({ history }) => {
  const styles = useStyle();

  const dispatch = useDispatch();
  const location = useLocation();
  const claimId = useSearchParam('claimId') || '';

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const { reason, documents: initialDocuments } = useSelector(
    state => state.claim
  );

  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState(
    !isEmpty(initialDocuments) ? initialDocuments : {}
  );

  const policyId = get(location, 'state.state.policyId');
  const handleBack = () =>
    history.push(`/claim/detail?claimId=${claimId}`, { policyId });
  const handleNext = async () => {
    setLoading(true);
    const formErrors = getErrors();
    if (isEmpty(formErrors)) {
      try {
        await api.updateFiles(policyId, claimId, {
          ...decamelizeKeys(documents),
        });
        dispatch(updateClaim({ documents }));
        history.push(`/claim/declaration?claimId=${claimId}`, { policyId });
      } catch (e) {
        setApiError(
          <Trans>
            Something went wrong, please contact our customer service.
          </Trans>
        );
      }
    } else {
      setErrors(formErrors);
    }
    setLoading(false);
  };
  const handleChange = name => e => {
    const val = get(e, 'target.value', e);
    setDocuments({ ...documents, [name]: val });
  };

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);
  useEffect(() => {
    const getReason = async () => {
      try {
        const res = await api.getReason(policyId, claimId);
        dispatch(updateClaim({ reason: get(res, 'data.claim_type') }));
      } catch (e) {
        setApiError(
          <Trans>
            Something went wrong, please contact our customer service.
          </Trans>
        );
      }
    };
    if (claimId && !reason) {
      getReason();
    }
    if (!claimId) {
      history.push('/');
    }
  }, [claimId, reason]);

  const treatmentRef = useRef(null);
  const documentsNeededRef = useRef(null);
  const ktpAndInheritanceRef = useRef(null);
  const laboratoryPhotosAndXRaysRef = useRef(null);
  const photoOfTheInsuredIdentityCardRef = useRef(null);
  const diagnosisCertificateRef = useRef(null);
  const originalStatementRef = useRef(null);
  const deathCertificateRef = useRef(null);
  const originalDeathCertificateRef = useRef(null);

  const field = 'document';

  const list = [
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      type: 'upload',
      multiple: true,
      linkStyle: styles.link,
      errors: errors[field],
      name: 'photoOfTheInsuredIdentityCard',
      ref: photoOfTheInsuredIdentityCardRef,
      value: get(documents, 'photoOfTheInsuredIdentityCard', []),
      title: <Trans>Photo of the Insured’s identity card </Trans>,
      helperText: <Trans> KTP / Birth Certificate / Family Card</Trans>,
    },
  ];

  const others = [
    {
      field,
      AWSUpload,
      multiple: true,
      getAWSUploadUrls,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: documentsNeededRef,
      name: 'otherDocumentsIfNeeded',
      value: get(documents, 'otherDocumentsIfNeeded', []),
      title: <Trans>Other documents if needed (depending on the case)</Trans>,
    },
  ];

  const laboratoryNoDeath = [
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      type: 'upload',
      multiple: true,
      errors: errors[field],
      linkStyle: styles.link,
      ref: laboratoryPhotosAndXRaysRef,
      name: 'laboratoryPhotosAndXRays',
      value: get(documents, 'laboratoryPhotosAndXRays', []),
      title: (
        <Trans>Photos of laboratory results / related x-rays / x-rays</Trans>
      ),
      helperText: (
        <Trans>Depending on the case, is there a lab / x-ray fee</Trans>
      ),
    },
  ];
  const laboratoryHaveDeath = [
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      type: 'upload',
      multiple: true,
      errors: errors[field],
      linkStyle: styles.link,
      ref: laboratoryPhotosAndXRaysRef,
      name: 'laboratoryPhotosAndXRays',
      value: get(documents, 'laboratoryPhotosAndXRays', []),
      title: <Trans>Laboratory photos & X-rays</Trans>,
      helperText: (
        <Trans>
          If death is in hospital; Depending on the case, is there a lab / X-ray
          fee
        </Trans>
      ),
    },
  ];
  const treatmentAndDiagnosis = [
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      ref: treatmentRef,
      errors: errors[field],
      linkStyle: styles.link,
      name: 'photoOfTreatmentReceiptAtTheHospital',
      value: get(documents, 'photoOfTreatmentReceiptAtTheHospital', []),
      title: <Trans>Photo of treatment receipt at the hospital </Trans>,
    },
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: diagnosisCertificateRef,
      name: 'photoOfDoctorsDiagnosisCertificate',
      value: get(documents, 'photoOfDoctorsDiagnosisCertificate', []),
      title: <Trans>Photo of doctor’s diagnosis certificate</Trans>,
    },
  ];

  const deathCertificate = [
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: originalDeathCertificateRef,
      name: 'photoOfOriginalDeathCertificateFromTheAuthorities',
      value: get(
        documents,
        'photoOfOriginalDeathCertificateFromTheAuthorities',
        []
      ),
      title: (
        <Trans>Photo of Original Death Certificate from the authorities</Trans>
      ),
    },
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: originalStatementRef,
      name: 'photoOfTheOriginalStatementOfTheHeir',
      value: get(documents, 'photoOfTheOriginalStatementOfTheHeir', []),
      title: <Trans>Photo of the Original Statement of the Heir</Trans>,
    },
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: ktpAndInheritanceRef,
      name: 'photoKtpAndInheritance',
      value: get(documents, 'photoKtpAndInheritance', []),
      title: <Trans>Photo KTP and Inheritance</Trans>,
      helperText: <Trans>KTP and KK</Trans>,
    },
    {
      field,
      AWSUpload,
      getAWSUploadUrls,
      required: true,
      multiple: true,
      type: 'upload',
      errors: errors[field],
      linkStyle: styles.link,
      ref: deathCertificateRef,
      name: 'photoOfOriginalDeathCertificateFromTheHospitals',
      value: get(
        documents,
        'photoOfOriginalDeathCertificateFromTheHospitals',
        []
      ),
      title: (
        <Trans>Photo of Original Death Certificate from the Hospital</Trans>
      ),
      helperText: <Trans>If died in hospital</Trans>,
    },
  ];

  const chooseUploadFileItems = () => {
    switch (reason) {
      case ACCIDENTAL_DEATH: {
        return [
          ...list,
          ...deathCertificate,
          ...laboratoryHaveDeath,
          ...others,
        ];
      }
      case MEDICAL_EXPENSES:
      case ACCIDENTAL_DEATH_MEDICAL_EXPENSES:
      case PERMANENT_DISABLEMENT: {
        return [
          ...list,
          ...treatmentAndDiagnosis,
          ...laboratoryNoDeath,
          ...others,
        ];
      }
      case PERMANENT_DISABLEMENT_MEDICAL_EXPENSES: {
        return [
          ...list,
          ...deathCertificate,
          ...treatmentAndDiagnosis,
          ...laboratoryHaveDeath,
          ...others,
        ];
      }
      default: {
        return list;
      }
    }
  };
  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Stepper totalSteps={5} curStep={3} />
      <Container maxWidth="xl">
        <Box className={styles.content}>
          <Typography variant="h1">
            <Trans>Claim Form</Trans>
          </Typography>
          <Box color="#6B778C" my={4}>
            <Typography variant="subtitle1">
              <Trans>
                Please make sure the information entered below is correct. Once
                confirmed, it cannot be changed
              </Trans>
            </Typography>
          </Box>
          <Box color="#FF6652" mt={10}>
            <Typography variant="h5">
              <Trans>5. Documents Required for Claim </Trans>
            </Typography>
          </Box>
          <Box mt={2} color="#6B778C">
            <Typography variant="subtitle2">
              <Trans>
                We require all documents below for your claim submission. Ensure
                all photos/documents uploaded are not in a blurry/glare state,
                and shows all details clearly.
              </Trans>
            </Typography>
          </Box>
          <Box mt={2} color="#6B778C">
            <Typography variant="subtitle2">
              <Trans>File formats can be PNG, JPEG or PDF.</Trans>
            </Typography>
          </Box>
          <Box marginY={4} padding={4} bgcolor="#F9FAFB">
            <Form list={chooseUploadFileItems()} handleChange={handleChange} />
          </Box>
          <ButtonGroup
            left={<Trans>Back</Trans>}
            right={<Trans>Next</Trans>}
            leftHandle={handleBack}
            rightHandle={handleNext}
            disabledRight={false}
          />
        </Box>
      </Container>
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </>
  );
};

Document.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default Document;
