import React from 'react';
import { Trans } from '@lingui/macro';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import IglooIcon from 'images/igloo.svg';

import Typography from './AllianzTypography';

const useStyle = makeStyles(() => ({
  iglooIcon: {
    width: 180,
    height: 80,
    left: '50%',
    right: '50%',
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    color: '#6B778C',
    position: 'absolute',
    alignItems: 'center',
    transform: 'translateX(-50%)',
    justifyContent: 'space-evenly',
  },
}));

const BottomIcon = () => {
  const styles = useStyle();
  return (
    <Box className={styles.iglooIcon}>
      <Typography variant="subtitle1">
        <Trans>Powered by</Trans>
      </Typography>
      <img src={IglooIcon} alt="igloo" />
    </Box>
  );
};

export default BottomIcon;
