/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField as MuiTextField,
  Typography,
  Divider,
} from '@material-ui/core';

function TextField({
  name,
  fullWidth,
  value,
  errors,
  helperText,
  onChange,
  startAdornment,
  endAdornment,
  disabled,
  InputProps,
  placeholder,
  autoComplete,
  multiline,
  rows,
}) {
  const { type, max, min } = InputProps;

  let startAdornmentComponent = null;
  if (typeof startAdornment === 'object') {
    startAdornmentComponent = startAdornment;
  } else {
    startAdornmentComponent = startAdornment && (
      <Box display="flex" height={20}>
        <Box pr={3} color="disabled.dark">
          <Typography variant="subtitle1" color="inherit">
            {startAdornment}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
      </Box>
    );
  }

  const endAdornmentComponent = endAdornment && (
    <Box display="flex" height={20}>
      <Divider orientation="vertical" />
      <Box pl={3} color="disabled.dark">
        <Typography variant="subtitle1" color="inherit">
          {endAdornment}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <MuiTextField
        autoComplete={autoComplete}
        multiline={multiline}
        rows={rows}
        error={!!errors[name]}
        variant="outlined"
        helperText={errors[name]}
        onChange={e => {
          if (type === 'number') {
            const targetValue = e.target.value.replace(/[,]+/g, '');
            if (targetValue === '') return onChange(targetValue);
            const targetNumber = Number(targetValue);
            if (max !== undefined && targetNumber > Number(max))
              return onChange(String(max));
            if (min !== undefined && targetNumber < Number(min))
              return onChange(String(min));
            return onChange(targetValue);
          }
          return onChange(e);
        }}
        onBlur={e => {
          if (type === 'number')
            onChange(e.target.value.replace(/[,]+/g, '') || '');
        }}
        fullWidth={fullWidth}
        value={value || ''}
        InputProps={{
          startAdornment: startAdornmentComponent,
          endAdornment: endAdornmentComponent,
          ...InputProps,
        }}
        disabled={disabled}
        placeholder={placeholder}
      />
      {helperText}
    </>
  );
}

TextField.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
  helperText: PropTypes.node,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  InputProps: PropTypes.object,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

TextField.defaultProps = {
  name: '',
  autoComplete: '',
  errors: {},
  fullWidth: true,
  helperText: null,
  startAdornment: null,
  endAdornment: null,
  disabled: false,
  value: '',
  InputProps: {},
  onChange: () => {},
  placeholder: '',
  multiline: false,
  rows: 1,
};

export default TextField;
