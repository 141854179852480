// eslint-disable-next-line
function _gtag() {
  // eslint-disable-next-line
  window.dataLayer.push(arguments);
}

// eslint-disable-next-line
const _gaOnceEventMap = {};

// init function
export default ({ production, development, history }) => {
  window.dataLayer = window.dataLayer || [];
  _gtag('js', new Date());
  _gtag(
    'config',
    process.env.REACT_APP_ENV === 'production' ? production : development
  );

  history.listen(location => {
    console.log('change', location.pathname + location.search);
    _gtag('set', 'page', location.pathname + location.search);
    _gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
    });
  });
};

// send event
export const ga = (action, { category, label }) => {
  _gtag('event', action, {
    event_category: category,
    event_label: label,
  });
};

// trigger only once
export const gaOnce = (action, { category, label }) => {
  if (!_gaOnceEventMap[`${action}-${category}-${label}`]) {
    _gtag('event', action, {
      event_category: category,
      event_label: label,
    });
    _gaOnceEventMap[`${action}-${category}-${label}`] = true;
  }
};
