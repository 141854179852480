import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Colors } from 'variables';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: Colors.green,
  },
  error: {
    backgroundColor: Colors.watermelon,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
};

function MessageBar({ open, variant, className, handleClose, message }) {
  const classes = useStyles();
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={6000}
      onClose={handleClose}
      open={open}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        className={classnames(classes[variant], className)}
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classnames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
      />
    </Snackbar>
  );
}

MessageBar.defaultProps = {
  message: null,
};

MessageBar.propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.node,
};

MessageBar.defaultProps = {
  className: '',
};

export default MessageBar;
