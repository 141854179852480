import React, { useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Form from 'components/Form';
import { ktpReg, phoneReg, emailReg } from 'constant/regex';

const genderOptions = [
  {
    value: 'M',
    label: <Trans>Male</Trans>,
  },
  {
    value: 'F',
    label: <Trans>Female</Trans>,
  },
];

const PersonalDetail = ({ personalDetails, errors, handleChange }) => {
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const birthRef = useRef(null);
  const mobileRef = useRef(null);
  const genderRef = useRef(null);
  const ktpNumberRef = useRef(null);
  const occupationRef = useRef(null);

  const field = 'personalDetails';

  const list = [
    {
      field,
      name: 'name',
      type: 'input',
      required: true,
      ref: nameRef,
      errors: errors[field],
      value: get(personalDetails, 'name'),
      title: <Trans>Name as on identification Document </Trans>,
    },
    {
      field,
      value: 'KTP',
      name: 'KTP',
      type: 'input',
      required: true,
      disabled: true,
      endAdornment: <ExpandMoreIcon />,
      title: <Trans>Identification Document Type</Trans>,
    },
    {
      field,
      type: 'input',
      regex: ktpReg,
      required: true,
      ref: ktpNumberRef,
      name: 'ktpNumber',
      errors: errors[field],
      title: <Trans>KTP Number </Trans>,
      InputProps: { type: 'number' },
      value: get(personalDetails, 'ktpNumber'),
      errorMsg: <Trans>Wrong KTP Number</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      disabled: true,
      name: 'Indonesia',
      endAdornment: <ExpandMoreIcon />,
      title: <Trans>Nationality </Trans>,
      value: get(personalDetails, 'nationality', 'Indonesia'),
    },
    {
      field,
      type: 'date',
      required: true,
      ref: birthRef,
      name: 'dateOfBirth',
      disableFuture: true,
      errors: errors[field],
      placeholder: 'DD / MM / YYYY',
      title: <Trans>Date of Birth</Trans>,
      value: get(personalDetails, 'dateOfBirth'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: emailRef,
      name: 'email',
      regex: emailReg,
      errors: errors[field],
      InputProps: { type: 'email' },
      title: <Trans>Email </Trans>,
      value: get(personalDetails, 'email'),
      errorMsg: <Trans>Wrong Email Address</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      ref: mobileRef,
      regex: phoneReg,
      name: 'mobileNumber',
      startAdornment: '+62',
      errors: errors[field],
      InputProps: { type: 'number' },
      title: <Trans>Mobile Number</Trans>,
      value: get(personalDetails, 'mobileNumber'),
      errorMsg: <Trans>Wrong Phone Number</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      ref: occupationRef,
      name: 'occupation',
      errors: errors[field],
      title: <Trans>Occupation </Trans>,
      value: get(personalDetails, 'occupation'),
    },
    {
      field,
      name: 'gender',
      ref: genderRef,
      required: true,
      type: 'radioGroup',
      errors: errors[field],
      options: genderOptions,
      title: <Trans>Gender</Trans>,
      value: get(personalDetails, 'gender', 'F'),
    },
  ];

  return (
    <>
      <Box color="#FF6652">
        <Typography variant="h5">
          <Trans>1. Your Personal Details </Trans>
        </Typography>
        <Box marginY={4} padding={4} bgcolor="#F9FAFB">
          <Form list={list} handleChange={handleChange} />
        </Box>
      </Box>
    </>
  );
};

PersonalDetail.propTypes = {
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  personalDetails: PropTypes.object.isRequired,
};

export default PersonalDetail;
