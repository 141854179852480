import { createMuiTheme } from '@material-ui/core/styles';
import { palette, spacing, fontFamily } from './variables';

const baseTheme = {
  spacing,
  palette,
  typography: {
    fontFamily,
    h1: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.17,
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.29,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.25,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.29,
    },
    body1: {
      fontSize: 12,
      lineHeight: 1.33,
    },
    body2: {
      fontSize: 10,
      lineHeight: 1.6,
    },
    caption: {
      fontSize: 12,
      fontStyle: 'italic',
      lineHeight: 1.33,
      display: 'block',
    },
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 750,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    Mui: {
      root: {
        '&$error': {
          color: palette.error.main,
        },
        '&$disabled': {
          color: palette.disabled.dark,
          opacity: 1, // compatible for safari
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: palette.primary.main,
        backgroundColor: palette.primary.contrastText,
        boxShadow: 'none',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: palette.disabled.main,
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: palette.primary.main, // #6c6cff
      },
      colorSecondary: {
        color: palette.secondary.main, // #6b778c
      },
      colorTextPrimary: {
        color: palette.secondary.dark, // #1f1f1f
      },
      colorTextSecondary: {
        color: palette.secondary.light, // #a6adba
      },
      colorError: {
        color: palette.error.main,
      },
    },
    MuiOutlinedInput: {
      root: {
        marginTop: 8,
        marginBottom: 4,
        height: 'auto',
        minHeight: 46,
        fontSize: 16,
        color: palette.secondary.dark,
        backgroundColor: palette.background.paper,
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.dark,
          },
          '&$disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.disabled.main,
          },
          '&$error .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error.dark,
          },
        },
        '&$disabled': {
          backgroundColor: palette.disabled.main,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.disabled.main,
          },
          '&$error .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error.dark,
          },
        },
      },
      notchedOutline: {
        borderColor: palette.disabled.main,
      },
      input: {
        padding: 'none',
        paddingLeft: 16,
        paddingRight: 16,
        '&::placeholder': {
          color: palette.secondary.light,
        },
      },
      inputAdornedStart: {
        paddingLeft: 12,
      },
      adornedStart: {
        paddingLeft: 16,
      },
      adornedEnd: {
        paddingRight: 16,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
      iconOutlined: {
        right: 16,
      },
    },
    MuiButton: {
      root: {
        height: 46,
      },
      containedPrimary: {
        color: palette.background.paper,
      },
      outlinedSecondary: {
        boxShadow:
          '0 1px 3px 0 rgba(196, 205, 213, 0.35), 0 1px 3px 0 rgba(0, 0, 0, 0.2)',
        '&:hover': {
          color: palette.primary.dark,
          backgroundColor: palette.background.paper,
          borderColor: palette.primary.dark,

          '&$disabled': {
            color: palette.disabled.main,
            borderColor: palette.disabled.main,
          },
        },
      },
      endIcon: {
        marginLeft: 4,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 8,
        margin: '0 8px 0 -8px',
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        '&:hover': {
          backgroundColor: palette.primary.contrastText,
        },
        '&$selected': {
          backgroundColor: palette.primary.contrastText,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: 0,
        borderRadius: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 24,
        paddingBottom: 0,
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: 0,
      },
    },
    MuiDialogContentText: {
      root: {
        marginBottom: 0,
        paddingBottom: 0,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 0,
      },
    },
  },
};

const createPlatformTheme = () => createMuiTheme(baseTheme);

export default createPlatformTheme;
