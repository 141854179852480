import trim from 'lodash/trim';

// convert phone number '0062 2323' => '+62 2323'
const phoneFormatter = phoneNumber => {
  const trimStr = trim(phoneNumber);
  const isStartWithZero = trimStr.startsWith('00');
  const slicedStr = trimStr.slice(2);

  return isStartWithZero
    ? slicedStr.padStart(slicedStr.length + 1, '+')
    : phoneNumber;
};

export default phoneFormatter;
