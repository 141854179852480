import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { Trans } from '@lingui/macro';
import { camelizeKeys } from 'humps';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { useLocation, useSearchParam } from 'react-use';
import { AppBar, Box, Container, Button } from '@material-ui/core';

import NotFound from 'app/404';
import ErrorPage from 'app/Error';
import NotAuthorized from 'app/401';

import gaInit from 'utils/ga';
import api from '@platform/api';
import { setUser } from 'app/userSlice';
import { setJWT, getJWT } from 'utils/jwt';

import iglooIcon from 'images/igloo.svg';
import LinearLoadingBar from 'components/LinearLoadingBar';

import Footer from './components/Footer';

import PolicyList from './feature/policyList';
import { setPolicies } from './feature/policyList/policiesSlice';

import Activation from './feature/activation';
import ActivationFail from './feature/activation/fail';
import ActivationSuccess from './feature/activation/success';
import ActivationInputCode from './feature/activation/input-code';
import ActivationErrorCode from './feature/activation/error-code';

import ClaimReason from './feature/claim/reason';
import ClaimDetail from './feature/claim/detail';
import ClaimDocument from './feature/claim/document';
import ClaimDeclaration from './feature/claim/declaration';
import ClaimSubmitSuccess from './feature/claim/submit-success';

import Reimbursement from './feature/reimbursement';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  header: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    backgroundColor: palette.primary.light,
    boxShadow: 'none',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backButton: {
    color: palette.primary.main,
  },
  activationContainer: {
    width: 400,
    display: 'flex',
    margin: 'auto',
    alignContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: `${spacing(6)}px ${spacing(4)}px 80px ${spacing(4)}px`,
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const PLATFORM = 'TELKOMSEL';
const GA_DEV_TOKEN = 'G-S8G858V773';
const GA_PROD_TOKEN = 'G-FWVE19S8DY';
function AppContainer({ history }) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const secret = useSearchParam('secret');
  const userId = useSearchParam('uid');

  const [loading, setLoading] = useState(false);
  const [authState, setAuthState] = useState('success');
  const isActivationPage = /activation/.test(pathname);

  useEffect(() => {
    gaInit({
      production: GA_PROD_TOKEN,
      development: GA_DEV_TOKEN,
      history,
    });
  }, [history]);

  useEffect(() => {
    const startUp = () => {
      setAuthState('success');
      api
        .getPolicies()
        .then(res => {
          setLoading(false);
          const policyList = get(res, 'data.results', []);
          const userInfo =
            camelizeKeys(
              get(policyList[0], 'activation.activation_form.insured_details')
            ) || {};
          dispatch(setUser(userInfo));
          dispatch(setPolicies(policyList));
        })
        .catch(() => {
          setLoading(false);
          history.push('/error');
        });
    };
    if (!isActivationPage) {
      setLoading(true);
      const JWT = getJWT(PLATFORM);
      if (secret) {
        api
          .getToken(secret, userId)
          .then(response => {
            const jwt = get(response, 'data.jwt_token');
            api.appendHeaders({
              'X-Axinan-User-Token': jwt,
            });
            setJWT(PLATFORM, jwt);
            startUp();
          })
          .catch(() => {
            setLoading(false);
            setAuthState('fail');
          });
      } else if (JWT) {
        api.appendHeaders({
          'X-Axinan-User-Token': JWT,
        });
        startUp();
      } else {
        setLoading(false);
        history.push('/error');
      }
    }
  }, []);

  return (
    <Box minHeight="100%" position="relative">
      <LinearLoadingBar loading={loading} />
      {isActivationPage ? (
        <Container className={styles.activationContainer}>
          <Switch>
            <Route
              path="/activation/input-code"
              component={ActivationInputCode}
            />
            <Route
              path="/activation/error-code"
              component={ActivationErrorCode}
            />
            <Route path="/activation/fail" component={ActivationFail} />
            <Route path="/activation/success" component={ActivationSuccess} />
            <Route path="/activation" component={Activation} />
          </Switch>
        </Container>
      ) : (
        <>
          <AppBar position="static" className={styles.header}>
            <Container className={styles.headerContainer}>
              <img src={iglooIcon} alt="igloo" />
              <Button
                className={styles.backButton}
                onClick={() => history.push('/')}
              >
                <Trans>MyPolicy</Trans>
              </Button>
            </Container>
          </AppBar>
          {authState === 'success' && (
            <>
              <div>
                <Switch>
                  <Route path="/" exact component={PolicyList} />
                  <Route path="/claim/reason" component={ClaimReason} />
                  <Route path="/claim/detail" component={ClaimDetail} />
                  <Route path="/claim/document" component={ClaimDocument} />
                  <Route
                    path="/claim/declaration"
                    component={ClaimDeclaration}
                  />
                  <Route
                    path="/claim/submit-success"
                    component={ClaimSubmitSuccess}
                  />
                  <Route path="/reimbursement" component={Reimbursement} />
                  <Route path="/error" component={ErrorPage} />
                  <Route component={NotFound} />
                </Switch>
              </div>
              <Footer />
            </>
          )}
          {authState === 'fail' && <NotAuthorized />}
        </>
      )}
    </Box>
  );
}

AppContainer.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default AppContainer;

export const currentPlatform = 'telkomsel';
