import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import get from 'lodash/get';
import set from 'lodash/set';
import trim from 'lodash/trim';
import { Trans } from '@lingui/macro';

import FormField from './FormField';
import TextField from './TextField';
import Checkbox from './Checkbox';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import Select from './Select';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import Upload from './Upload';

const form = {};

const scrollY = y => {
  window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth',
  });
};

const REQUIRED_ERROR = <Trans>This field is required.</Trans>;
const DOC_REQUIRED_ERROR = <Trans>This document is required.</Trans>;
export const getErrors = () => {
  const formErrors = {};
  let errorElOffset = null;
  Object.keys(form).forEach(key => {
    const list = form[key];
    Object.keys(list).forEach(item => {
      const {
        value,
        field,
        name,
        type,
        ref,
        regex,
        errorMsg,
        required,
        validator,
      } = list[item];

      const fieldError = get(formErrors, field, {});
      const valueType = typeof value;
      const trimmed = valueType === 'boolean' ? value : trim(value);

      if (!trimmed && required) {
        fieldError[name] =
          type === 'upload' ? DOC_REQUIRED_ERROR : REQUIRED_ERROR;
        Object.assign(formErrors, { [field]: fieldError });

        const itemOffset = get(ref, 'current.offsetTop', null);
        if (!errorElOffset) errorElOffset = itemOffset;
        else if (itemOffset)
          errorElOffset =
            itemOffset < errorElOffset ? itemOffset : errorElOffset;
        return;
      }

      if (trimmed && regex) {
        const reg = RegExp(regex);

        if (!reg.test(trimmed)) {
          fieldError[name] = errorMsg;
          Object.assign(formErrors, { [field]: fieldError });

          const itemOffset = get(ref, 'current.offsetTop', null);
          if (!errorElOffset) errorElOffset = itemOffset;
          else if (itemOffset)
            errorElOffset =
              itemOffset < errorElOffset ? itemOffset : errorElOffset;
        }
      }

      if (!!validator && validator()) {
        fieldError[name] = errorMsg;
        Object.assign(formErrors, { [field]: fieldError });
      }
    });
  });

  if (errorElOffset) scrollY(errorElOffset);

  return formErrors;
};

export const setErrors = errorArr => {
  const formErrors = {};

  let errorElOffset = null;
  errorArr.forEach(({ field, name, errorMsg }) => {
    const fieldError = get(formErrors, field, {});
    fieldError[name] = errorMsg;
    formErrors[field] = fieldError;

    const itemOffset = get(
      form,
      `${field}.${name}.ref.current.offsetTop`,
      null
    );
    if (!errorElOffset) errorElOffset = itemOffset;
    else if (itemOffset)
      errorElOffset = itemOffset < errorElOffset ? itemOffset : errorElOffset;
  });

  if (errorElOffset) scrollY(errorElOffset);

  return formErrors;
};

const Form = ({ list, handleChange }) => {
  const formList = useMemo(() => {
    const components = {
      input: TextField,
      checkbox: Checkbox,
      radioGroup: RadioGroup,
      checkboxGroup: CheckboxGroup,
      select: Select,
      date: DatePicker,
      time: TimePicker,
      upload: Upload,
    };
    return list.map(item => {
      const {
        field,
        type,
        name,
        ref,
        value,
        title,
        subTitle,
        helperText,
        errors,
        required,
        disabled,
        onChange,
        asterisk,
        explanation,
        sm = 6,
        xs = 12,
        ...rest
      } = item;
      set(form, `${field}.${name}`, item);
      const Component = components[type];
      if (!Component) return null;
      return (
        <Grid key={name} item xs={xs} sm={sm} ref={ref}>
          <FormField
            required={required}
            title={title}
            subTitle={subTitle}
            disabled={disabled}
            type={type}
            asterisk={asterisk}
            explanation={explanation}
          >
            <Component
              field={field}
              value={value}
              errors={errors}
              name={name}
              onChange={e => {
                if (get(errors, name)) delete errors[name];
                if (onChange) onChange(e);
                else handleChange(name)(e);
              }}
              helperText={
                <Typography variant="caption" color="textSecondary">
                  {helperText}
                </Typography>
              }
              disabled={disabled}
              required={required}
              {...rest}
            />
          </FormField>
        </Grid>
      );
    });
  }, [list, handleChange]);

  useEffect(() => {
    list.forEach(item => set(form, `${item.field}.${item.name}`, item));
    return () => list.forEach(({ field, name }) => delete form[field][name]);
  }, [list]);

  return (
    <Grid container spacing={2}>
      {formList}
    </Grid>
  );
};

Form.propTypes = {
  list: PropTypes.array,
  handleChange: PropTypes.func,
};

Form.defaultProps = {
  list: [],
  handleChange: () => {},
};

export default Form;
