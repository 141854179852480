import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';

import Warning from './warning.png';
import Error from './error.png';
import Expired from './expired.svg';

const useStyles = makeStyles(theme => ({
  infoPaperWarning: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
  },
  infoPaperError: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
  },
  infoPaperExpired: {
    color: theme.palette.expired.main,
    backgroundColor: theme.palette.expired.light,
  },
  warningIcon: {
    width: 24,
    height: 24,
  },
  blockIcon: {
    fontSize: 20,
    color: theme.palette.error.main,
    marginRight: 8,
  },
}));

const classObj = {
  error: 'infoPaperError',
  warning: 'infoPaperWarning',
  expired: 'infoPaperExpired',
};

const srcObj = {
  error: Error,
  warning: Warning,
  expired: Expired,
};

function InfoPaper({ type, children }) {
  const classes = useStyles();
  return (
    <Box
      p={4}
      fontSize={14}
      display="flex"
      alignItems="center"
      className={classes[classObj[type]]}
    >
      <Box mx={2}>
        <img
          alt="warning icon"
          src={srcObj[type]}
          className={classes.warningIcon}
        />
      </Box>
      <Typography variant="subtitle1">{children}</Typography>
    </Box>
  );
}

InfoPaper.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'expired']).isRequired,
};

export default InfoPaper;
