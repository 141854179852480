import React from 'react';
import { Trans } from '@lingui/macro';
import { Box } from '@material-ui/core';

import { ResultBox } from '../success';
import BottomIcon from '../components/BottomIcon';

import wrong from '../img/error.svg';
import allianzTelkomselIcon from '../img/allianz-telkomsel.svg';

const Fail = () => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <img src={allianzTelkomselIcon} alt="allianz-telkomsel" />
      </Box>
      <ResultBox
        icon={wrong}
        content={
          <Trans>
            Unfortunately, your activation failed because the verification code
            you entered is incorrect.
          </Trans>
        }
      />
      <BottomIcon />
    </>
  );
};

export default Fail;
