import React from 'react';
import { Trans } from '@lingui/macro';
import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from './AllianzTypography';

import phone from '../img/phone.svg';
import email from '../img/email.svg';

const Contact = () => {
  const styles = makeStyles(() => ({
    font: { fontSize: 12 },
    bold: { fontWeight: 600 },
  }))();

  return (
    <Box my={8}>
      <Divider />
      <Box p={4}>
        <Typography
          variant="subtitle2"
          className={`${styles.font} ${styles.bold}`}
        >
          <Trans>Layanan Dan Pengaduan</Trans>
        </Typography>
        <Box mt={4} display="flex" justifyContent="space-between" width="70%">
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <img src={phone} alt="phone" />
            <Box ml={2.5}>
              <Typography className={styles.font}>
                <Trans>1500136 (4)</Trans>
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <img src={email} alt="email" />
            <Box ml={2.5}>
              <Typography className={styles.font}>
                <Trans>cs@allianz.co.id</Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
