import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { decamelizeKeys } from 'humps';
import defaultsDeep from 'lodash/defaultsDeep';
import RRPropTypes from 'react-router-prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParam, useLocation } from 'react-use';
import { Box, Container, Typography } from '@material-ui/core';
import { isDate, getTime, parse, format, addHours, addMinutes } from 'date-fns';

import api from '@platform/api';
import MessageBar from 'components/MessageBar';
import { numberWithPoint } from 'utils/number';
import LinearLoadingBar from 'components/LinearLoadingBar';
import { getErrors, setErrors as setFormErrors } from 'components/Form';

import Stepper from '../../../components/Stepper';
import ButtonGroup from '../../components/ButtonGroup';

import Contact from './Contact';
import AccidentDetail from './AccidentDetail';
import PersonalDetail from './PersonalDetail';
import InsuranceDetail from './InsuranceDetail';

import { updateClaim } from '../claimSlice';
import {
  CLAIMED_BENEFIT,
  ACCIDENTAL_DEATH,
  MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT,
  ACCIDENT_DAY_HAPPENED_LIMIT,
  ACCIDENT_DEATH_CLAIM_AMOUNT,
  MEDICAL_EXPENSES_CLAIM_AMOUNT,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES,
  PERMANENT_DISABLEMENT_MEDICAL_EXPENSES,
  ACCIDENTAL_DEATH_MEDICAL_EXPENSES_CLAIM_AMOUNT,
} from '../constant';

const useStyle = makeStyles(({ breakpoints, spacing }) => ({
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    padding: spacing(4),
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const DATE_FORMAT = 'dd-MM-yyyy';
const REQUEST_FORMAT = 'yyyy-MM-dd';

const isDateHappenedWithinRange = (
  dateHappened,
  policyEndAt,
  policyStartAt,
  dayLimit
) => {
  if (!isDate(dateHappened) || !isDate(policyEndAt) || !isDate(policyStartAt))
    return false;

  const dayRangeTimestamp = dayLimit * 24 * 60 * 60 * 1000;
  const curDayTimestamp = getTime(new Date());
  const isInDayRange =
    curDayTimestamp <= dayRangeTimestamp + getTime(dateHappened);
  const isInPolicyPeriod =
    getTime(policyEndAt) >= getTime(dateHappened) &&
    getTime(dateHappened) >= getTime(policyStartAt);
  return isInDayRange && isInPolicyPeriod;
};

const parseTime = time => {
  if (!time) return { hours: 0, minutes: 0 };
  const hours = time.split(':')[0];
  const minutes = time.split(':')[1];
  return { hours, minutes };
};

const chooseAmountLimit = reason => {
  switch (reason) {
    case MEDICAL_EXPENSES:
      return MEDICAL_EXPENSES_CLAIM_AMOUNT;
    case ACCIDENTAL_DEATH_MEDICAL_EXPENSES:
    case PERMANENT_DISABLEMENT_MEDICAL_EXPENSES:
      return ACCIDENTAL_DEATH_MEDICAL_EXPENSES_CLAIM_AMOUNT;
    default:
      return 0;
  }
};

const isInClaimAmountRange = (reason, value) => {
  const limit = chooseAmountLimit(reason);
  return limit === 0 ? true : limit >= value;
};

const Detail = ({ history }) => {
  const styles = useStyle();
  const location = useLocation();
  const claimId = useSearchParam('claimId') || '';
  const claims = useSelector(state => state.claim);
  const {
    reason,
    contact: initialContact,
    accidentDetails: initialAccidentDetails,
    personalDetails: initialPersonalDetails,
    insuranceDetails: initialInsuranceDetails,
  } = claims;
  const policies = useSelector(state => state.policies);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [contact, setContact] = useState(
    !isEmpty(initialContact) ? initialContact : {}
  );
  const [personalDetails, setPersonalDetails] = useState(
    !isEmpty(initialPersonalDetails) ? initialPersonalDetails : {}
  );
  const [accidentDetails, setAccidentDetails] = useState(
    !isEmpty(initialAccidentDetails) ? initialAccidentDetails : {}
  );
  const [insuranceDetails, setInsuranceDetails] = useState(
    !isEmpty(initialInsuranceDetails) ? initialInsuranceDetails : {}
  );

  const policyId = get(location, 'state.state.policyId');
  const handleBack = () => {
    history.push(`/claim/reason?claimId=${claimId}`, { policyId });
  };
  const handleNext = async () => {
    let checkedErrors = null;
    const policy = policies.filter(
      item => get(item, 'policy.id', '') === policyId
    );
    const policyEndAt = get(policy[0], 'policy.end_at');
    const policyStartAt = get(policy[0], 'policy.start_at');
    const dateOfAccidentHappened = parse(
      get(accidentDetails, 'dateOfAccident'),
      DATE_FORMAT,
      new Date()
    );
    const { minutes, hours } = parseTime(
      get(accidentDetails, 'timeOfAccident')
    );
    let dateHappened = dateOfAccidentHappened;
    dateHappened = addHours(dateHappened, hours);
    dateHappened = addMinutes(dateHappened, minutes);
    const isDateOfAccidentValid = isDateHappenedWithinRange(
      dateHappened,
      new Date(policyEndAt * 1000),
      new Date(policyStartAt * 1000),
      ACCIDENT_DAY_HAPPENED_LIMIT
    );
    const isAmountValid = isInClaimAmountRange(
      reason,
      get(accidentDetails, 'claimAmount')
    );
    if (!isDateOfAccidentValid) {
      checkedErrors = defaultsDeep(
        checkedErrors,
        setFormErrors([
          {
            field: 'accidentDetails',
            name: 'dateOfAccident',
            errorMsg: (
              <Trans>
                The loss could be claimed only when the accident occurred within
                the period your policy covered.
              </Trans>
            ),
          },
        ])
      );
    }
    if (!isAmountValid) {
      checkedErrors = defaultsDeep(
        checkedErrors,
        setFormErrors([
          {
            field: 'accidentDetails',
            name: 'claimAmount',
            errorMsg: (
              <span>
                <Trans>
                  The claim amount exceeds the maximum benefit of IDR
                </Trans>
                {'  '}
                {numberWithPoint(chooseAmountLimit(reason))}
              </span>
            ),
          },
        ])
      );
    }
    const formErrors = getErrors();
    checkedErrors = defaultsDeep(checkedErrors, formErrors);
    if (!isEmpty(checkedErrors)) {
      setErrors(checkedErrors);
    } else {
      dispatch(
        updateClaim({
          contact,
          personalDetails,
          accidentDetails,
          insuranceDetails,
        })
      );
      try {
        const requestBody = defaultsDeep(
          {},
          { contact, personalDetails, accidentDetails, insuranceDetails }
        );

        if (!String(requestBody.personalDetails.mobileNumber).startsWith('0')) {
          requestBody.personalDetails.mobileNumber = `0${requestBody.personalDetails.mobileNumber}`;
        }
        requestBody.personalDetails.dateOfBirth = format(
          parse(
            get(personalDetails, 'dateOfBirth', new Date()),
            DATE_FORMAT,
            new Date()
          ),
          REQUEST_FORMAT
        );
        requestBody.accidentDetails.dateOfAccident = format(
          dateOfAccidentHappened,
          REQUEST_FORMAT
        );
        requestBody.insuranceDetails.amountOfPremiumPaid = String(
          insuranceDetails.amountOfPremiumPaid
        );
        await api.updateDetails(policyId, claimId, decamelizeKeys(requestBody));
        history.push(`/claim/document?claimId=${claimId}`, { policyId });
      } catch (e) {
        setApiError(
          <Trans>
            Something went wrong, please contact our customer service.
          </Trans>
        );
      }
    }
    setLoading(false);
  };

  const handleChange = type => name => e => {
    let val = get(e, 'target.value', e);
    if (type === 'contact') setContact({ ...contact, [name]: val });
    if (type === 'personalDetails')
      setPersonalDetails({ ...personalDetails, [name]: val });
    if (type === 'insuranceDetails')
      setInsuranceDetails({ ...insuranceDetails, [name]: val });
    if (type === 'accidentDetails') {
      if (reason === PERMANENT_DISABLEMENT && name === 'claimedBenefit') {
        const rate = get(
          CLAIMED_BENEFIT.find(item => item.value === val),
          'rate'
        );
        setAccidentDetails({
          ...accidentDetails,
          ...{
            claimAmount: Number.isNaN(ACCIDENT_DEATH_CLAIM_AMOUNT * rate)
              ? ''
              : ACCIDENT_DEATH_CLAIM_AMOUNT * rate,
            claimedBenefit: val,
          },
        });
      } else {
        if (name === 'claimAmount') {
          val = Number.isNaN(Number(val.replace(/\./g, '')))
            ? ''
            : Number(val.replace(/\./g, ''));
        }

        setAccidentDetails({ ...accidentDetails, [name]: val });
      }
    }
  };

  useEffect(() => {
    setInsuranceDetails(initialInsuranceDetails);
    setPersonalDetails(initialPersonalDetails);
    setContact(initialContact);
    setAccidentDetails(initialAccidentDetails);
  }, [
    reason,
    initialContact,
    initialPersonalDetails,
    initialAccidentDetails,
    initialInsuranceDetails,
  ]);

  useEffect(() => {
    const getReason = async () => {
      try {
        const res = await api.getReason(policyId, claimId);
        dispatch(updateClaim({ reason: get(res, 'data.claim_type') }));
      } catch (e) {
        setApiError(
          <Trans>
            Something went wrong, please contact our customer service.
          </Trans>
        );
      }
    };
    if (claimId && !reason) {
      getReason();
    }
    if (!claimId) {
      history.push('/');
    }
  }, [claimId, reason]);

  useEffect(() => {
    switch (reason) {
      case ACCIDENTAL_DEATH: {
        setAccidentDetails(acDetails => ({
          ...acDetails,
          ...{
            claimAmount: ACCIDENT_DEATH_CLAIM_AMOUNT,
            claimedBenefit: reason,
          },
        }));
        break;
      }
      case MEDICAL_EXPENSES:
      case ACCIDENTAL_DEATH_MEDICAL_EXPENSES: {
        setAccidentDetails(acDetails => ({
          ...acDetails,
          ...{ claimedBenefit: reason },
        }));
        break;
      }
      default:
    }
  }, [reason]);

  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Stepper totalSteps={5} curStep={2} />
      <Container maxWidth="xl">
        <Box className={styles.content}>
          <Typography variant="h1">
            <Trans>Claim Form</Trans>
          </Typography>
          <Box color="#6B778C" my={4}>
            <Typography variant="subtitle1">
              <Trans>
                Please make sure the information entered below is correct. Once
                confirmed, it cannot be changed
              </Trans>
            </Typography>
          </Box>
          <PersonalDetail
            errors={errors}
            personalDetails={personalDetails}
            handleChange={handleChange('personalDetails')}
          />
          <Contact
            errors={errors}
            contact={contact}
            handleChange={handleChange('contact')}
          />
          <InsuranceDetail
            insuranceDetails={insuranceDetails}
            handleChange={handleChange('insuranceDetails')}
          />
          <AccidentDetail
            reason={reason}
            errors={errors}
            accidentDetails={accidentDetails}
            handleChange={handleChange('accidentDetails')}
          />
          <ButtonGroup
            left={<Trans>Back</Trans>}
            right={<Trans>Next</Trans>}
            leftHandle={handleBack}
            rightHandle={handleNext}
            disabledRight={false}
          />
        </Box>
      </Container>
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </>
  );
};

Detail.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default Detail;
