import React, { useRef } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@material-ui/core';

import Form from 'components/Form';

import { PROVINCES } from '../constant';

const Contact = ({ contact, errors, handleChange }) => {
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const postalCodeRef = useRef(null);
  const addressLineRef = useRef(null);

  const field = 'contact';

  const list = [
    {
      field,
      sm: 12,
      type: 'input',
      required: true,
      ref: addressLineRef,
      name: 'address1',
      errors: errors[field],
      title: <Trans>Address Line1</Trans>,
      value: get(contact, 'address1'),
    },
    {
      field,
      sm: 12,
      type: 'input',
      name: 'address2',
      title: <Trans>Address Line2 (optional)</Trans>,
      value: get(contact, 'address2'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: cityRef,
      name: 'city',
      errors: errors[field],
      title: <Trans>City</Trans>,
      value: get(contact, 'city'),
    },
    {
      field,
      type: 'select',
      required: true,
      ref: stateRef,
      name: 'state',
      errors: errors[field],
      options: PROVINCES,
      placeHolder: <Trans>- Select -</Trans>,
      title: <Trans>State</Trans>,
      value: get(contact, 'state'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: postalCodeRef,
      name: 'postalCode',
      errors: errors[field],
      title: <Trans>Postal Code</Trans>,
      value: get(contact, 'postalCode'),
    },
  ];

  return (
    <>
      <Box color="#FF6652" mt={10}>
        <Typography variant="h5">
          <Trans>2. Contact </Trans>
        </Typography>
      </Box>
      <Box mt={2} color="#6B778C">
        <Typography variant="subtitle1">
          <Trans>
            Please make sure you get these details right! We collect your info
            in line with our Privacy Policy
          </Trans>
        </Typography>
      </Box>
      <Box marginY={3} padding={4} bgcolor="#F9FAFB">
        <Form list={list} handleChange={handleChange} />
      </Box>
    </>
  );
};

Contact.propTypes = {
  errors: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Contact;
