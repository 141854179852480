import { createSlice } from '@reduxjs/toolkit';

const claimSlice = createSlice({
  name: 'claim',
  initialState: {
    reason: '',
    contact: {},
    documents: {},
    personalDetails: { nationality: 'Indonesia', gender: 'F' },
    accidentDetails: {},
    insuranceDetails: {},
  },
  reducers: {
    updateClaim(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateClaim } = claimSlice.actions;
export default claimSlice.reducer;
