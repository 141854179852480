export const thousandsSeparator = (value, separator = ',') => {
  return (value || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export function numberWithPoint(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const numberFormatter = (displayValue, decimal = 0) =>
  displayValue
    .replace(/\b[-]/g, '')
    .replace(/\B[.]/g, '')
    .replace(/(?<=\d+\.\d+)[.]/, '')
    .replace(new RegExp(`(?<=\\d+\\.\\d{${decimal}})\\d`), '')
    .replace(/[-]{2,}/g, '-')
    .replace(/[.]{2,}/g, '.')
    .replace(/[^\d.-]/g, '');

export const amountParser = (value, decimal = 0) =>
  numberFormatter(value, decimal).replace(/^[0]{1}(?=\d)/g, '');

export const rupiah = value => {
  if (value) {
    const numberString = value.toString();
    const split = numberString.split(',');
    const sisa = split[0].length % 3;
    let left = split[0].substr(0, sisa);
    const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    if (ribuan) {
      const separator = sisa ? '.' : '';
      left += separator + ribuan.join('.');
    }

    left = split[1] !== undefined ? `${left},${split[1]}` : left;
    return left;
  }
  return value;
};
