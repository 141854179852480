import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, Box, Grid } from '@material-ui/core';
import RRPropTypes from 'react-router-prop-types';

import ClaimStatus from './constants/ClaimStatus';

import CancelClaimConfirmModal from './components/CancelClaimConfirmModal';

const useStyle = makeStyles(() => ({
  cancelSubmit: {
    color: '#6B778C',
    backgroundColor: '#fff',
    border: '1px solid #A6ADBA',
    '&:hover': {
      background: '#fff',
    },
  },
}));

function RoutedButton({
  state,
  id,
  history,
  reimburseState,
  handleReimburse,
  handleCancelClaim,
  handleReSubmitClaim,
  handleCancelReimburse,
}) {
  const styles = useStyle();
  const [open, setOpen] = useState(false);

  const onCancel = () => {
    handleCancelClaim();
    setOpen(false);
  };

  switch (state) {
    case ClaimStatus.CLAIM_APPROVED: {
      const needReimburseStateMap = [
        ClaimStatus.REIMBURSEMENT_CANCELLED,
        ClaimStatus.REIMBURSEMENT_FAILED,
      ];
      if (reimburseState === ClaimStatus.REIMBURSEMENT_PROCESSING) {
        return (
          <Box minWidth={180}>
            <Button
              size="large"
              color="primary"
              className={styles.cancelSubmit}
              variant="contained"
              onClick={handleCancelReimburse}
            >
              <Trans>Cancel Reimbursement</Trans>
            </Button>
          </Box>
        );
      }
      if (needReimburseStateMap.includes(reimburseState) || !reimburseState) {
        return (
          <Box minWidth={180}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={handleReimburse}
            >
              <Trans>Reimburse</Trans>
            </Button>
          </Box>
        );
      }
      return null;
    }

    case ClaimStatus.CLAIM_PROCESSING:
      return (
        <Box minWidth={180}>
          <Button
            size="large"
            color="primary"
            className={styles.cancelSubmit}
            variant="contained"
            onClick={() => setOpen(true)}
          >
            <Trans>Cancel Claim</Trans>
          </Button>
          <CancelClaimConfirmModal
            open={open}
            onProceed={onCancel}
            closeModal={() => setOpen(false)}
          />
        </Box>
      );
    case ClaimStatus.CLAIM_PENDING:
      return (
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={12} sm="auto">
            <Button
              fullWidth
              size="large"
              color="primary"
              className={styles.cancelSubmit}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              <Trans>Cancel Claim</Trans>
            </Button>
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(`/claim/reason?id=${id}`);
                handleReSubmitClaim();
              }}
            >
              <Trans>Re-Submit Claim</Trans>
            </Button>
          </Grid>

          <CancelClaimConfirmModal
            open={open}
            onProceed={onCancel}
            closeModal={() => setOpen(false)}
          />
        </Grid>
      );

    default:
      return null;
  }
}

RoutedButton.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  history: RRPropTypes.history.isRequired,
  handleReimburse: PropTypes.func.isRequired,
  reimburseState: PropTypes.string.isRequired,
  handleCancelClaim: PropTypes.func.isRequired,
  handleReSubmitClaim: PropTypes.func.isRequired,
  handleCancelReimburse: PropTypes.func.isRequired,
};

export default withRouter(RoutedButton);
