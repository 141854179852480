import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box, Typography } from '@material-ui/core';

import { Colors } from 'variables';

import PolicyStatus from '../constants/PolicyStatus';

const statusMap = {
  [PolicyStatus.PROTECTED]: {
    label: (
      <Typography variant="subtitle1">
        <Trans>PROTECTED</Trans>
      </Typography>
    ),
    color: Colors.algaeGreen,
    bgColor: Colors.paleGreen,
  },
  [PolicyStatus.POLICY_EXPIRED]: {
    label: (
      <Typography variant="subtitle1">
        <Trans>POLICY EXPIRED</Trans>
      </Typography>
    ),
    color: Colors.dimGray,
    bgColor: Colors.athensGray,
  },
  [PolicyStatus.POLICY_END]: {
    label: (
      <Typography variant="subtitle1">
        <Trans>POLICY END</Trans>
      </Typography>
    ),
    color: Colors.lightSteelBlue,
    bgColor: Colors.athensGray,
  },
  [PolicyStatus.POLICY_ISSUED]: {
    label: (
      <Typography variant="subtitle1">
        <Trans>POLICY ISSUED</Trans>
      </Typography>
    ),
    color: '#108043',
    bgColor: '#EEFFEA',
  },
};

function PolicyStatusLabel({ state }) {
  if (!statusMap[state]) return null;
  const { bgColor, color, label } = statusMap[state];
  return (
    <Box
      py={1}
      px={2}
      border={1}
      fontSize={10}
      color={color}
      borderRadius={4}
      fontWeight={600}
      bgcolor={bgColor}
      borderColor={color}
    >
      {label}
    </Box>
  );
}

PolicyStatusLabel.propTypes = {
  state: PropTypes.string.isRequired,
};

export default PolicyStatusLabel;
