import React, { useState } from 'react';
import get from 'lodash/get';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { camelizeKeys } from 'humps';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Grid,
  Divider,
  Button,
  Collapse,
  ButtonBase,
  Typography,
} from '@material-ui/core';

import { Colors } from 'variables';

import DetailBox from './components/DetailBox';
import PolicyStatusLabel from './components/PolicyStatusLabel';

import ClaimItem from './ClaimItem';
import { updateClaim } from '../claim/claimSlice';
import PolicyStatus from './constants/PolicyStatus';

import TelkomselIcon from './telkomsel.png';

const useStyles = makeStyles(() => ({
  titleContainer: {
    width: 'fit-content',
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  title: {
    fontWeight: 600,
  },
  link: {
    fontSize: 12,
    paddingBottom: 4,
  },
  expandIcon: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.2s',
  },
}));

const DATE_FORMAT = 'dd-MM-yyyy';
const formatDate = (date, dateFormat = DATE_FORMAT) => {
  if (!date) return '';
  return format(new Date(date), dateFormat);
};
function PolicyItem({ data, setReload, history }) {
  const dispatch = useDispatch();
  const {
    state,
    policy,
    policyId,
    claimList,
    claimable,
    coveragePeriod,
    displayPolicyId,
  } = data;

  const classes = useStyles();
  const [showClaimList, setShowClaimList] = useState(!!claimList.length);
  const initialInsuranceDetails = {
    endPeriod: policy.endAt,
    amountOfPremiumPaid: '5000',
    startPeriod: policy.startAt,
    telkomselNumber: policy.userId,
    voucherNumber: policy.voucherNumber,
    packageName: 'Telkomsel Pre-Paid 1 Month',
  };
  const handleReSubmitClaim = claim => {
    const claimDetails = camelizeKeys(get(claim, 'claimForm', {}));
    if (!isEmpty(claimDetails.personalDetails))
      claimDetails.personalDetails.dateOfBirth = formatDate(
        get(claimDetails, 'personalDetails.dateOfBirth')
      );
    if (!isEmpty(claimDetails.accidentDetails))
      claimDetails.accidentDetails.dateOfAccident = formatDate(
        get(claimDetails, 'accidentDetails.dateOfAccident')
      );
    dispatch(
      updateClaim({
        reason: get(claim, 'claimType', ''),
        contact: claimDetails.contact || {},
        insuranceDetails: initialInsuranceDetails,
        personalDetails: claimDetails.personalDetails || {},
        accidentDetails: claimDetails.accidentDetails || {},
        documents: claimDetails.documentsRequiredForClaim || {},
      })
    );
    history.push(`/claim/reason?claimId=${get(claim, 'claimId')}`, {
      policyId,
    });
  };

  const handleClaimNow = () => {
    dispatch(
      updateClaim({
        reason: '',
        contact: {},
        documents: {},
        personalDetails: {
          nationality: 'Indonesia',
          gender: 'F',
        },
        accidentDetails: {},
        insuranceDetails: initialInsuranceDetails,
      })
    );
    history.push('/claim/reason', {
      policyId,
    });
  };
  return (
    <Box
      p={6}
      mt={4}
      color={Colors.dark}
      bgcolor={Colors.white}
      border="1px solid #EBECF0"
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          className={classes.titleContainer}
        >
          <Grid item>
            <PolicyStatusLabel state={state} />
          </Grid>
          <Grid item xs={12} sm="auto">
            <Box display="flex" alignItems="center">
              <img
                src={TelkomselIcon}
                alt="telkomsel label"
                className={classes.icon}
              />
              <Typography className={classes.title} variant="subtitle1">
                <Trans>Telkomsel</Trans>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Box minWidth={180}>
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              onClick={handleClaimNow}
              disabled={!(state === PolicyStatus.PROTECTED && claimable)}
            >
              <Trans>Claim Now</Trans>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box mt={6}>
        <Divider light />
      </Box>
      <Grid container direction="row" justify="space-between">
        <DetailBox label={<Trans>Policy ID</Trans>}>
          <Typography variant="subtitle1">{displayPolicyId}</Typography>
        </DetailBox>
        <DetailBox label={<Trans>Category</Trans>}>
          <Typography variant="subtitle1">
            <Trans>Personal Accident</Trans>
          </Typography>
        </DetailBox>
        <DetailBox label={<Trans>Coverage Name</Trans>}>
          <Typography variant="subtitle1">
            <Trans>Telkomsel Pre-Paid</Trans>
          </Typography>
        </DetailBox>
        <DetailBox label={<Trans>Coverage Period</Trans>}>
          <Typography variant="subtitle1">{coveragePeriod}</Typography>
        </DetailBox>
      </Grid>
      {!!claimList.length && (
        <>
          <Box mt={8} mb={1} color="#6C6CFF">
            <ButtonBase onClick={() => setShowClaimList(!showClaimList)}>
              <Typography variant="subtitle2">
                <Trans>Claim History</Trans>
              </Typography>
              <ExpandMoreIcon
                color="primary"
                className={showClaimList && classes.expandIcon}
              />
            </ButtonBase>
          </Box>
          <Collapse in={showClaimList}>
            {claimList.map(claim => (
              <ClaimItem
                data={claim}
                history={history}
                key={claim.claimId}
                policyId={policyId}
                setReload={setReload}
                handleReSubmitClaim={handleReSubmitClaim}
              />
            ))}
          </Collapse>
        </>
      )}
    </Box>
  );
}

PolicyItem.propTypes = {
  data: PropTypes.shape({
    policy: PropTypes.object.isRequired,
    state: PropTypes.string.isRequired,
    claimable: PropTypes.bool.isRequired,
    policyId: PropTypes.string.isRequired,
    claimList: PropTypes.array.isRequired,
    coveragePeriod: PropTypes.string.isRequired,
    displayPolicyId: PropTypes.string.isRequired,
  }).isRequired,
  setReload: PropTypes.func.isRequired,
  history: RRPropTypes.history.isRequired,
};

export default PolicyItem;
