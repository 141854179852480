import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Typography } from '@material-ui/core';

const ButtonGroup = ({
  left,
  right,
  leftHandle,
  rightHandle,
  disabledRight,
}) => {
  return (
    <Grid container alignItems="center" justify="space-between" spacing={2}>
      <Grid item sm="auto" xs={12}>
        <Box minWidth={100}>
          <Button variant="outlined" fullWidth onClick={leftHandle}>
            <Typography variant="subtitle1">{left}</Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item sm="auto" xs={12}>
        <Box minWidth={110}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={rightHandle}
            disabled={disabledRight}
          >
            <Typography variant="subtitle1">{right}</Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

ButtonGroup.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
  leftHandle: PropTypes.func.isRequired,
  rightHandle: PropTypes.func.isRequired,
  disabledRight: PropTypes.bool.isRequired,
};

export default ButtonGroup;
