import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';

import { Colors } from 'variables';

const FormField = props => {
  const {
    title,
    subTitle,
    required,
    children,
    disabled,
    type,
    asterisk,
    explanation,
  } = props;
  return (
    <Box mt={5} height="100%">
      <Box display="flex" alignItems="center">
        {title && (
          <Typography variant="subtitle2" color="textSecondary">
            {title}{' '}
            {required && !disabled && asterisk && type !== 'checkbox' && '*'}
          </Typography>
        )}
        {explanation}
      </Box>
      <Typography variant="caption" style={{ color: Colors.darkGrey }}>
        {subTitle}
      </Typography>
      {children}
    </Box>
  );
};

FormField.defaultProps = {
  required: false,
  title: null,
  subTitle: null,
  disabled: false,
  asterisk: true,
  type: 'input',
  explanation: null,
};

FormField.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  asterisk: PropTypes.bool,
  type: PropTypes.string,
  explanation: PropTypes.node,
};

export default FormField;
