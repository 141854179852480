import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, IconButton } from '@material-ui/core';

import { ReactComponent as PdfIcon } from './pdf.svg';
import { ReactComponent as PngIcon } from './png.svg';
import { ReactComponent as JpgIcon } from './jpg.svg';
import { ReactComponent as JpegIcon } from './jpeg.svg';
import { ReactComponent as CrossIcon } from './cross.svg';

const FileList = ({ url, handleRemove, linkStyle }) => {
  if (!url) {
    return <></>;
  }

  const name = url.split('/').pop();
  const type = name.split('.').pop();

  let icon = null;
  switch (type) {
    case 'pdf':
      icon = <PdfIcon />;
      break;
    case 'png':
      icon = <PngIcon />;
      break;
    case 'jpg':
      icon = <JpgIcon />;
      break;
    case 'jpeg':
      icon = <JpegIcon />;
      break;
    default:
      break;
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height={46}
      mb={1}
    >
      <Link
        href={url}
        target="_blank"
        underline="none"
        color="primary"
        variant="h6"
        className={linkStyle}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box ml={4.25} mr={3}>
            {icon}
          </Box>
          {decodeURI(name)}
        </Box>
      </Link>
      <Box mr={4.5}>
        <IconButton onClick={handleRemove}>
          <CrossIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

FileList.propTypes = {
  url: PropTypes.string.isRequired,
  handleRemove: PropTypes.func,
  linkStyle: PropTypes.string,
};

FileList.defaultProps = {
  handleRemove: () => {},
  linkStyle: '',
};

export default FileList;
