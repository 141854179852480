import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

function LinearLoadingBar({ loading }) {
  if (!loading) return null;
  return (
    <Box position="fixed" top={0} left={0} width={1} height={1} zIndex={1600}>
      <LinearProgress />
    </Box>
  );
}

LinearLoadingBar.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default LinearLoadingBar;
