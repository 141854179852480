import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const useStyles = makeStyles({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: 0,
  },
  progress: {
    width: '100%',
    height: '8px',
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: '#EBECF0',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#6B778C',
    },
  },
});

export default function ProgressStepper({ totalSteps, curStep }) {
  const classes = useStyles();
  return (
    <MobileStepper
      steps={totalSteps}
      position="static"
      variant="progress"
      activeStep={curStep}
      classes={{ progress: classes.progress, root: classes.root }}
    />
  );
}

ProgressStepper.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  curStep: PropTypes.number.isRequired,
};
