import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { parse, isValid } from 'date-fns';

import { ReactComponent as DatePickerIcon } from './datepicker.svg';
import { invalidDateFormat, maxDateMessage, minDateMessage } from './errors';

function DatePicker({
  value,
  inputValue,
  fullWidth,
  name,
  errors,
  helperText,
  disablePast,
  disableFuture,
  onChange,
  disabled,
  maxDate,
  minDate,
  maxDateAlert,
  minDateAlert,
  format,
  shouldDisableDate,
  placeholder,
}) {
  const [displayValue, setDisplayValue] = useState(value);
  const [select, setSelect] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (value) setDisplayValue(value);
  }, [value]);

  return (
    <>
      <KeyboardDatePicker
        // autoOk
        disabled={disabled}
        error={!!errors[name] || !!error}
        helperText={error || errors[name]}
        onChange={(date, val) => {
          const update = (val || '').replace(/ \/ /g, '-');
          if (displayValue !== update) setDisplayValue(update);
        }}
        inputValue={inputValue || displayValue.replace(/-/g, ' / ')}
        fullWidth={fullWidth}
        disablePast={disablePast}
        disableFuture={disableFuture}
        format={format}
        inputVariant="outlined"
        placeholder={placeholder || format.toLocaleUpperCase()}
        KeyboardButtonProps={{
          'aria-label': 'change date of birth',
        }}
        keyboardIcon={disabled ? null : <SvgIcon component={DatePickerIcon} />}
        invalidDateMessage={invalidDateFormat}
        maxDateMessage={maxDateAlert || maxDateMessage}
        minDateMessage={minDateAlert || minDateMessage}
        onError={(err, date) => {
          setError(err);

          if (
            isValid(date) &&
            !value &&
            value !== displayValue &&
            !err &&
            !select
          )
            onChange(displayValue);

          if (!isValid(date) && value && value !== displayValue) onChange();
        }}
        onOpen={() => {
          setSelect(true);
          onChange();
        }}
        onClose={() => {
          setSelect(false);
        }}
        value={displayValue ? parse(displayValue, format, new Date()) : null}
        maxDate={maxDate}
        minDate={minDate}
        shouldDisableDate={shouldDisableDate}
      />
      {helperText}
    </>
  );
}

DatePicker.propTypes = {
  value: PropTypes.string,
  inputValue: PropTypes.string,
  format: PropTypes.string,
  errors: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  shouldDisableDate: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxDateAlert: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  minDateAlert: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  value: '',
  inputValue: null,
  name: '',
  format: 'dd / MM / yyyy',
  errors: {},
  disablePast: false,
  disableFuture: false,
  fullWidth: true,
  helperText: null,
  disabled: false,
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01'),
  maxDateAlert: null,
  minDateAlert: null,
  shouldDisableDate: () => false,
  placeholder: '',
};

export default DatePicker;
