import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { Colors, fontFamily } from 'variables';

import ClaimStatus from '../constants/ClaimStatus';

const statusMap = {
  [ClaimStatus.CLAIM_PROCESSING]: {
    label: <Trans>CLAIM PROCESSING</Trans>,
    bgColor: Colors.lightLime,
  },
  [ClaimStatus.CLAIM_CANCELLED]: {
    label: <Trans>CLAIM CANCELLED</Trans>,
    bgColor: Colors.athensGray,
  },
  [ClaimStatus.CLAIM_REJECTED]: {
    label: <Trans>CLAIM REJECTED</Trans>,
    bgColor: '#FDF4F2',
  },
  [ClaimStatus.CLAIM_PENDING]: {
    label: <Trans>CLAIM PENDING</Trans>,
    bgColor: Colors.orangeYellow,
  },
  [ClaimStatus.CLAIM_EXPIRED]: {
    label: <Trans>CLAIM EXPIRED</Trans>,
    bgColor: '#F4F5F7',
  },
  [ClaimStatus.CLAIM_APPROVED]: {
    label: <Trans>CLAIM APPROVED</Trans>,
    bgColor: '#EEFFEA',
  },
  [ClaimStatus.REIMBURSEMENT_PROCESSING]: {
    label: <Trans>REIMBURSEMENT PROCESSING</Trans>,
    bgColor: '#E6FEFE',
  },
  [ClaimStatus.REIMBURSED]: {
    label: <Trans>REIMBURSED</Trans>,
    bgColor: '#EEFFEA',
  },
  [ClaimStatus.REIMBURSEMENT_FAILED]: {
    label: <Trans>REIMBURSEMENT FAILED</Trans>,
    bgColor: '#FDF4F2',
  },
  [ClaimStatus.REIMBURSEMENT_CANCELLED]: {
    label: <Trans>REIMBURSEMENT CANCELLED</Trans>,
    bgColor: '#F4F5F7',
  },
};

const colorMap = {
  [ClaimStatus.CLAIM_PROCESSING]: '#1A4E80',
  [ClaimStatus.CLAIM_CANCELLED]: Colors.lightSteelBlue,
  [ClaimStatus.CLAIM_REJECTED]: '#DE3618',
  [ClaimStatus.CLAIM_APPROVED]: '#108043',
  [ClaimStatus.CLAIM_EXPIRED]: '#6B778C',
  [ClaimStatus.REIMBURSEMENT_PROCESSING]: '#1A4E80',
  [ClaimStatus.REIMBURSED]: '#108043',
  [ClaimStatus.REIMBURSEMENT_FAILED]: '#DE3618',
  [ClaimStatus.REIMBURSEMENT_CANCELLED]: '#6B778C',
};

function ClaimStatusLabel({ status }) {
  if (!statusMap[status]) return null;
  const { bgColor, label } = statusMap[status];
  return (
    <Box
      py={1}
      px={2}
      fontSize={10}
      display="inline"
      borderRadius={4}
      bgcolor={bgColor}
      fontWeight={600}
      fontFamily={fontFamily}
      color={colorMap[status] || Colors.white}
    >
      {label}
    </Box>
  );
}

ClaimStatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ClaimStatusLabel;
