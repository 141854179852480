import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { telkomselFontFamily } from 'variables';

const AllianzTypography = withStyles(() => ({
  root: {
    fontFamily: telkomselFontFamily,
  },
}))(Typography);

export default AllianzTypography;
