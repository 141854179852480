import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { camelizeKeys } from 'humps';

import api from '@platform/api';
import { setLoading } from 'app/globalSlice';

const getProfile = createAsyncThunk(
  'user/getProfile',
  async (param, { dispatch }) => {
    await dispatch(setLoading(true));
    const { data } = await api
      .getProfile()
      .finally(() => dispatch(setLoading(false)));
    return data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUser(state, action) {
      return action.payload;
    },
    updateUser(state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      const profile = camelizeKeys(get(action, 'payload', {}));
      return profile;
    },
  },
});

export { getProfile };
export const { setUser, updateUser } = userSlice.actions;
export default userSlice.reducer;
