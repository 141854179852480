import { createSlice } from '@reduxjs/toolkit';

const policiesSlice = createSlice({
  name: 'policies',
  initialState: [],
  reducers: {
    setPolicies(state, action) {
      return action.payload;
    },
  },
});

export const { setPolicies } = policiesSlice.actions;
export default policiesSlice.reducer;
