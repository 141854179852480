import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Box } from '@material-ui/core';

import Contact from '../components/Contact';
import BottomIcon from '../components/BottomIcon';
import Typography from '../components/AllianzTypography';

import right from '../img/right.svg';
import allianzTelkomselIcon from '../img/allianz-telkomsel.svg';

export const ResultBox = ({ icon, content }) => {
  return (
    <Box
      marginTop={4}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        display="flex"
        marginTop={10}
        marginBottom={6}
        alignItems="center"
        justifyContent="center"
      >
        <img src={icon} alt="error warning" />
      </Box>
      <Typography variant="subtitle1">{content}</Typography>
    </Box>
  );
};
ResultBox.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

const Success = () => {
  return (
    <>
      <Box display="flex" justifyContent="center">
        <img src={allianzTelkomselIcon} alt="allianz-telkomsel" />
      </Box>
      <ResultBox
        icon={right}
        content={
          <Trans>
            You have successfully registered as an Allianz Insurance
            participant. The policy will be sent via email / SMS. For more
            information, contact 1500136 (4)
          </Trans>
        }
      />
      <Contact />
      <BottomIcon />
    </>
  );
};

export default Success;
