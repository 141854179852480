import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';

import Form from 'components/Form';
import { numberWithPoint } from 'utils/number';

const DATE_FORMAT = 'dd-MM-yyyy';

const InsuranceDetail = ({ insuranceDetails, handleChange }) => {
  const field = 'insuranceDetails';

  const formatUnixTimestampsToString = unixTimestamp =>
    format(new Date(unixTimestamp * 1000), DATE_FORMAT);

  const list = [
    {
      field,
      type: 'input',
      // required: true,
      disabled: true,
      name: 'telkomselNumber',
      title: <Trans>Telkomsel Number</Trans>,
      value: get(insuranceDetails, 'telkomselNumber', ''),
    },
    {
      field,
      type: 'input',
      // required: true,
      disabled: true,
      name: 'voucherNumber',
      title: <Trans>Voucher Number</Trans>,
      value: get(insuranceDetails, 'voucherNumber', ''),
    },
    {
      field,
      type: 'date',
      // required: true,
      disabled: true,
      name: 'startPeriod',
      placeholder: 'DD / MM / YYYY',
      title: <Trans>Start Period</Trans>,
      value: formatUnixTimestampsToString(
        get(insuranceDetails, 'startPeriod', 0)
      ),
    },
    {
      field,
      type: 'date',
      // required: true,
      disabled: true,
      name: 'endPeriod',
      placeholder: 'DD / MM / YYYY',
      title: <Trans>End Period</Trans>,
      value: formatUnixTimestampsToString(
        get(insuranceDetails, 'endPeriod', 0)
      ),
    },
    {
      field,
      type: 'input',
      // required: true,
      disabled: true,
      name: 'packageName',
      title: <Trans>Package Name</Trans>,
      value: get(insuranceDetails, 'packageName', ''),
    },
    {
      field,
      type: 'input',
      // required: true,
      disabled: true,
      startAdornment: 'IDR',
      name: 'amountOfPremiumPaid',
      title: <Trans>Amount of Premium Paid </Trans>,
      value: numberWithPoint(get(insuranceDetails, 'amountOfPremiumPaid', '')),
    },
  ];
  return (
    <>
      <Box color="#FF6652" mt={10}>
        <Typography variant="h5">
          <Trans>3. Insurance Details </Trans>
        </Typography>
      </Box>
      <Box mt={2} color="#6B778C">
        <Typography variant="subtitle1">
          <Trans>Below is the information of your Insurance Details</Trans>
        </Typography>
      </Box>
      <Box marginY={4} padding={4} bgcolor="#F9FAFB">
        <Form list={list} handleChange={handleChange} />
      </Box>
    </>
  );
};

InsuranceDetail.propTypes = {
  handleChange: PropTypes.func.isRequired,
  insuranceDetails: PropTypes.object.isRequired,
};

export default InsuranceDetail;
