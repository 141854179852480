import React, { useState, useRef, useEffect } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { decamelizeKeys } from 'humps';
import { useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { Box, Container, Typography } from '@material-ui/core';

import api from '@platform/api';
import MessageBar from 'components/MessageBar';
import Form, { getErrors } from 'components/Form';
import LinearLoadingBar from 'components/LinearLoadingBar';

import ButtonGroup from '../components/ButtonGroup';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    padding: spacing(4),
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  link: {
    overflow: 'hidden',
    width: '100%',
    maxHeight: spacing(4),
  },
}));

const AWSUpload = api.uploadFile;
const getAWSUploadUrls = api.getUrlsToUpload;

const Reimbursement = ({ history }) => {
  const styles = useStyles();
  const claimId = useSearchParam('claimId') || '';
  const policyId = useSearchParam('policyId') || '';
  const user = useSelector(state => state.user);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [bank, setBank] = useState({ accountName: user.fullName });

  const bankNameRef = useRef(null);
  const branchNamRef = useRef(null);
  const bankPassbookRef = useRef(null);
  const accountNumberRef = useRef(null);
  const letterStatementRef = useRef(null);

  useEffect(() => {
    const accountName = user.fullName;
    setBank({ ...bank, ...{ accountName } });
  }, [user]);

  const handleCancel = () => history.push('/');
  const handleSubmit = async () => {
    setLoading(true);
    const formErrors = getErrors();
    if (isEmpty(formErrors)) {
      try {
        await api.reimburse(policyId, claimId, {
          ...decamelizeKeys(bank),
        });
        history.push('/');
      } catch (e) {
        setApiError(
          <Trans>
            Something went wrong, please contact our customer service.
          </Trans>
        );
      }
    } else {
      setErrors(formErrors);
    }
    setLoading(false);
  };

  const handleChange = name => e => {
    const val = get(e, 'target.value', e);
    setBank({ ...bank, [name]: val });
  };
  const field = 'bank';
  const list = [
    {
      field,
      type: 'input',
      disabled: true,
      name: 'accountName',
      value: get(bank, 'accountName'),
      title: <Trans>Account Name</Trans>,
    },
    {
      field,
      type: 'input',
      required: true,
      ref: bankNameRef,
      name: 'bankName',
      errors: errors[field],
      title: <Trans>Bank Name</Trans>,
      value: get(bank, 'bankName'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: branchNamRef,
      name: 'bankBranchName',
      errors: errors[field],
      title: <Trans>Bank Branch Name </Trans>,
      value: get(bank, 'bankBranchName'),
    },
    {
      field,
      type: 'input',
      required: true,
      ref: accountNumberRef,
      name: 'accountNumber',
      errors: errors[field],
      title: <Trans>Account Number </Trans>,
      value: get(bank, 'accountNumber'),
    },
    {
      field,
      AWSUpload,
      required: true,
      multiple: true,
      type: 'upload',
      getAWSUploadUrls,
      errors: errors[field],
      linkStyle: styles.link,
      ref: bankPassbookRef,
      name: 'photoOfBankPassbook',
      value: get(bank, 'photoOfBankPassbook', []),
      title: <Trans>Photo of Bank Passbook </Trans>,
      helperText: (
        <Trans>
          Bank Passbook indicating Bank Name, Bank Account Number and Account
          Name
        </Trans>
      ),
    },
    {
      field,
      AWSUpload,
      required: true,
      type: 'upload',
      multiple: true,
      getAWSUploadUrls,
      errors: errors[field],
      linkStyle: styles.link,
      ref: letterStatementRef,
      name: 'letterOfStatement',
      value: get(bank, 'letterOfStatement', []),
      title: <Trans>Letter of Statement </Trans>,
      helperText: (
        <Trans>
          Letter of statement if the Bank Account Name is different with the
          claim beneficiary names
        </Trans>
      ),
    },
  ];
  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Container maxWidth="xl">
        <Box className={styles.content}>
          <Typography variant="h1">
            <Trans>Bank Account Details</Trans>
          </Typography>
          <Box color="#6B778C" my={4}>
            <Typography variant="subtitle1">
              <Trans>
                Please enter your bank account details for reimbursement
                purposes. The name entered below has to be identical with the
                policy holder‘s name, and the name registered to the bank.
              </Trans>
            </Typography>
          </Box>
          <Box bgcolor="#F9FAFB" padding={8} my={6}>
            <Form list={list} handleChange={handleChange} />
          </Box>
          <ButtonGroup
            disabledRight={false}
            leftHandle={handleCancel}
            rightHandle={handleSubmit}
            left={<Trans>Cancel</Trans>}
            right={<Trans>Submit</Trans>}
          />
        </Box>
      </Container>
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
    </>
  );
};

Reimbursement.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default Reimbursement;
