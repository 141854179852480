import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Button, Dialog } from '@material-ui/core';

import { Trans } from '@lingui/macro';

const useStyle = makeStyles(() => ({
  cancel: {
    color: '#6B778C',
    backgroundColor: '#fff',
    border: '1px solid #A6ADBA',
    '&:hover': {
      background: '#fff',
    },
  },
}));

function CancelClaimConfirmModal({ open, onProceed, closeModal }) {
  const styles = useStyle();
  return (
    <Dialog open={open} onClose={closeModal} maxWidth="xs">
      <Box p={6}>
        <Box pt={2} pb={12}>
          <Typography variant="subtitle1">
            <Trans>Are you sure you want to cancel this claim?</Trans>
          </Typography>
        </Box>
        <Box>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={onProceed}
          >
            <Trans>Yes, cancel claim now</Trans>
          </Button>
        </Box>
        <Box mt={3}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={closeModal}
            className={styles.cancel}
          >
            <Trans>No, I do not want to cancel claim</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

CancelClaimConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

export default CancelClaimConfirmModal;
