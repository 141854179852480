import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
  name: 'user',
  initialState: {
    loading: true,
    error: null,
  },
  reducers: {
    setLoading(state, action) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setError(state, action) {
      return {
        ...state,
        error: action.payload,
      };
    },
  },
});

export const { setLoading, setError } = globalSlice.actions;
export default globalSlice.reducer;
