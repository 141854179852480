import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/styles';
import { useSearchParam, useLocation } from 'react-use';
import RRPropTypes from 'react-router-prop-types';
import {
  Box,
  Dialog,
  Button,
  Divider,
  Checkbox,
  Container,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

import api from '@platform/api';

import Stepper from '../../../components/Stepper';

import ButtonGroup from '../../components/ButtonGroup';
import { TextSlide } from '../../activation/components/ConfirmModal';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    padding: spacing(4),
    boxSizing: 'border-box',
    [breakpoints.down('md')]: {
      width: '100%',
    },
  },
  openModal: {
    fontWeight: 500,
    cursor: 'pointer',
    color: 'rgb(96, 97, 255)',
  },
  bolder: {
    fontWeight: 500,
  },
  confirmButton: {
    width: 212,
    color: 'white',
    backgroundColor: 'rgb(77, 78, 255)',
  },
}));

const Declaration = ({ history }) => {
  const styles = useStyles();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const claimId = useSearchParam('claimId') || '';
  const policyId = get(location, 'state.state.policyId');

  const handleBack = () =>
    history.push(`/claim/document?claimId=${claimId}`, { policyId });

  const handleNext = () => {
    api.submitClaim(policyId, claimId);
    history.push('/claim/submit-success');
  };

  const closeModal = () => {
    setChecked(true);
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };
  return (
    <>
      <Stepper totalSteps={5} curStep={4} />
      <Container maxWidth="xl">
        <Box className={styles.content}>
          <Typography variant="h1">
            <Trans>Declaration</Trans>
          </Typography>
          <Box bgcolor="#F9FAFB" padding={8} my={6} display="flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  color="primary"
                />
              }
            />
            <>
              <Typography variant="subtitle1">
                <Trans>
                  Dengan mengklik tombol ajukan di bawah, maka dengan ini Saya
                  menyetujui dan menerima
                </Trans>{' '}
                <Typography
                  variant="span"
                  className={styles.openModal}
                  onClick={openModal}
                >
                  <Trans>Syarat dan Ketentuan</Trans>
                </Typography>{' '}
                <Trans>PT Asuransi Allianz Utama Indonesia (“Allianz”).</Trans>
              </Typography>
            </>
          </Box>
          <ButtonGroup
            leftHandle={handleBack}
            rightHandle={handleNext}
            disabledRight={!checked}
            left={<Trans>Back</Trans>}
            right={<Trans>Submit</Trans>}
          />
        </Box>
        <ConfirmModal open={open} closeModal={closeModal} setOpen={setOpen} />
      </Container>
    </>
  );
};

Declaration.propTypes = {
  history: RRPropTypes.history.isRequired,
};

const ConfirmModal = ({ open, closeModal, setOpen }) => {
  const styles = useStyles();
  return (
    <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
      <Box>
        <Box display="flex" alignItems="center" height={60} px={4}>
          <Box textAlign="start" width="90%">
            <Typography variant="h4" className={styles.bolder}>
              <Trans>Terms and Conditions</Trans>
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box px={6} py={3} overflow="scroll" color="#6B778C" fontSize={16}>
        <TextSlide>
          <Trans>
            1. Sehubungan dengan pengajuan klaim yang saya ajukan, Saya dengan
            ini memberi kuasa kepada PT Asuransi Allianz Utama Indonesia
            (“Allianz”) untuk:
          </Trans>
        </TextSlide>
        <TextSlide>
          <Box px={3}>
            <Trans>
              a. Mengungkapkan informasi pribadi Saya kepada perusahaan
              eksternal yang telah diperiksa oleh Allianz melalui proses
              penyaringan identifikasi untuk bekerja atas nama Allianz guna
              menyediakan beberapa fungsi terkait bisnis tertentu;
            </Trans>
          </Box>
        </TextSlide>
        <TextSlide>
          <Box px={3}>
            <Trans>
              b. Mengungkapkan informasi pribadi Saya untuk berbagi data dengan
              perusahaan induk Allianz, afiliasi, anak perusahaan, agen, usaha
              patungan dan mitra strategis untuk melakukan fungsi bisnis
              terkait;
            </Trans>
          </Box>
        </TextSlide>
        <TextSlide>
          <Box px={3}>
            <Trans>
              c. Mengungkapkan informasi pribadi Saya dalam hal menanggapi
              perintah pengadilan atau proses hukum atau permintaan dari
              regulator manapun atau pihak berwenang atau untuk melindungi
              terhadap penipuan atau aktivitas ilegal lainnya atau untuk tujuan
              manajemen risiko atau untuk memungkinkan Allianz untuk melakukan
              upaya hukum yang tersedia atau membatasi kerusakan yang mungkin
              terjadi terhadap Allianz, dan/atau mematuhi hukum dan proses hukum
              termasuk tetapi tidak terbatas pada peraturan tentang FATCA dan
              CRS (“Kebutuhan Pelaporan Pertukaran Informasi Antar Negara”).
              Saya juga bersedia untuk memberikan informasi/dokumen yang
              diperlukan guna memenuhi kebutuhan terkait (“Informasi Terkait”)
              dan dengan segera menyampaikan pengikian data kepada Allianz jika
              ada perubahan atas Informasi Terkait tersebut.
            </Trans>
          </Box>
        </TextSlide>
        <TextSlide>
          <Trans>
            2. Saya, dengan ini juga memberi kuasa kepada setiap dokter, rumah
            sakit, klinik, puskesmas, instansi lain, perusahaan asuransi, badan
            hukum, perorangan atau organisasi lainnya, yang mempunyai catatan
            atau mengetahui keadaan atau kesehatan Saya, riwayat pengobatan atau
            perawatan di rumah sakit, nasihat-nasihat dokter baik selama Saya
            masih hidup atau meninggal dunia dan/atau setiap dokter, rumah
            sakit, klinik, puskesmas, instansi lain, perusahaan asuransi, badan
            hukum, perorangan atau organisasi lainnya yang ditunjuk atau
            direkomendasikan oleh Allianz, untuk memberitahu dan mengungkapkan
            kepada Allianz atau mereka yang diberi kuasa olehnya, segala
            keterangan mengenai diri dan kesehatan Saya.
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            3. Saya dengan ini melepaskan ketentuan-ketentuan yang termaktub
            dalam Pasal 1813, 1814, dan 1816 Kitab Undang-Undang Hukum Perdata,
            serta pemberian kuasa ini tidak dapat ditarik kembali dan mengikat.
            Salinan (fotokopi) surat kuasa ini adalah sah dan mempunyai kekuatan
            hukum yang sama seperti aslinya.
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            4. Semua dengan ini juga menyatakan bahwa keterangan yang Saya
            berikan di dalam website/platform Allianz (“Platform”) dan
            keterangan lain yang Saya sampaikan kepada Allianz adalah benar dan
            tidak ada keterangan-keterangan dan hal-hal lain yang Saya
            sembunyikan, serta Saya sendiri yang mengisi serta menandatangani
            secara elektronik permohonan pengajuan klaim ini.
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            5. Saya menyetujui bahwa apabila ternyata pernyataan, keterangan,
            dokumen dan jawaban yang Saya berikan tidak benar/diragukan
            kebenarannya, maka Saya memahami serta menyetujui sepenuhnya bahwa
            Polis menjadi batal dan Allianz dibebaskan dari segala kewajibannya
            membayar klaim dan/atau manfaat asuransi lainnya, segala gugatan,
            tuntutan, klaim atau bagian dari itu dalam bentuk dan nama apapun,
            baik saat ini maupun di kemudian hari.
          </Trans>
        </TextSlide>
        <TextSlide>
          <Trans>
            6. Saya mengerti bahwa untuk mempelajari lebih lanjut tentang
            bagaimana Allianz melindungi data pribadi dan hak-hak Saya dengan
            mengakses tautan berikut:
            http://www.allianz.co.id/data-privasi/prinsip-privasi.
          </Trans>
        </TextSlide>
        <Divider />
        <Box height={48} py={4} display="flex" justifyContent="flex-end">
          <Box minWidth={212}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={closeModal}
            >
              <Trans>Accept Terms and Conditions</Trans>
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default Declaration;
