import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormHelperText,
  Radio,
  RadioGroup as MUIRadioGroup,
  Typography,
  Grid,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { ReactComponent as CheckedIcon } from './checked.svg';
import { ReactComponent as EmptyIcon } from './empty.svg';

const useStyles = makeStyles(() => ({
  menu: {
    background: '#fff',
    borderColor: '#ebecf0',
    height: '100%',
  },
}));

function RadioGroup({
  value,
  onChange,
  name,
  errors,
  disabled,
  helperText,
  options,
}) {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" alignItems="center" mt={2}>
        <MUIRadioGroup
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          style={{ width: '100%' }}
        >
          <Grid container spacing={2}>
            {options.map(item => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={item.value}
                  style={{ marginBottom: 12 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    color="disabled.dark"
                    border={1}
                    borderRadius={4}
                    pl={4}
                    pr={2}
                    py={1}
                    className={classes.menu}
                  >
                    <Typography
                      variant="subtitle1"
                      color={disabled ? 'inherit' : 'textPrimary'}
                    >
                      {item.label}
                    </Typography>
                    <Radio
                      value={item.value}
                      color="primary"
                      icon={<EmptyIcon />}
                      checkedIcon={<CheckedIcon />}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </MUIRadioGroup>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

RadioGroup.propTypes = {
  value: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
};

RadioGroup.defaultProps = {
  value: '',
  disabled: false,
  onChange: () => {},
  errors: {},
  name: '',
  helperText: '',
};

export default RadioGroup;
