import React from 'react';
import { Trans } from '@lingui/macro';
import { Container, Box, Typography, Link, Divider } from '@material-ui/core';

const CopyRight = () => (
  <Container>
    <Box display="flex" pb={3.75}>
      <Box flex={1}>
        <Typography color="textSecondary" variant="body1">
          © {new Date().getFullYear()}{' '}
          <Trans>Powered by Axinan Pte Ltd. All Rights Reserved.</Trans>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography component="div" color="primary" variant="body1">
          <Box fontWeight="500">
            <Link href="https://www.igloo.insure/id-pp">
              <Trans>Privacy Policy</Trans>
            </Link>
          </Box>
        </Typography>
        <Box mx={4} height={30}>
          <Divider orientation="vertical" />
        </Box>
        <Typography component="div" color="primary" variant="body1">
          <Box fontWeight={500}>
            <Link href="https://www.igloo.insure/id-termsofservice">
              <Trans>Terms of Service</Trans>
            </Link>
          </Box>
        </Typography>
      </Box>
    </Box>
  </Container>
);

export default CopyRight;
