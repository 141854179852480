import React from 'react';
import { Trans } from '@lingui/macro';
import { Container, Box, Typography, Link } from '@material-ui/core';

import { ReactComponent as IglooIcon } from 'images/igloo.svg';
import apple from 'images/apple.png';
import google from 'images/google.png';

const Links = () => (
  <Container>
    <Box display="flex" pt={3.75} pb={3.25} alignItems="center">
      <Box flex={1}>
        <IglooIcon width={57} height={32} />
      </Box>
      <Typography variant="body1" color="textSecondary">
        <Trans>Download Now</Trans>
      </Typography>
      <Box ml={3.5}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Link href="https://apps.apple.com/id/app/igloo-asuransi-digital/id1459971846">
            <Box
              component="img"
              width={96}
              height={32}
              mr={2}
              src={apple}
              alt="apple store"
            />
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=com.axinan.galadriel.id">
            <Box
              component="img"
              width={96}
              height={32}
              src={google}
              alt="google play store"
            />
          </Link>
        </Box>
      </Box>
    </Box>
  </Container>
);

export default Links;
