import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import createPlatformTheme from 'theme';
// eslint-disable-next-line import/named
import Platform from '@platform';
import i18n from '@locales';

import store from './store';

// eslint-disable-line
const basePath = process.env.BASEPATH;

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
});

function App() {
  return (
    <ThemeProvider theme={createPlatformTheme()}>
      <Provider store={store}>
        <I18nProvider i18n={i18n}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router basename={basePath}>
              <ScrollToTop>
                <Switch>
                  <Route path="/" component={Platform} />
                </Switch>
              </ScrollToTop>
            </Router>
          </MuiPickersUtilsProvider>
        </I18nProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
