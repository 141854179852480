import React from 'react';
import { Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/styles';
import RRPropTypes from 'react-router-prop-types';
import { Container, Typography, Box } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { Colors } from 'variables';
import VerticalCenterLayout from 'components/VerticalCenterLayout';

import submitted from './submitted.png';

const useStyles = makeStyles(theme => ({
  img: { width: 64, height: 64 },
  link: { color: '#6C6CFF', cursor: 'pointer', fontSize: 16 },
  content: {
    width: '50%',
    margin: 'auto',
    minHeight: 500,
    boxSizing: 'border-box',
    padding: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

const ClaimSubmitSuccess = ({ history }) => {
  const classes = useStyles();
  const jumpToHome = () => history.push('/');

  return (
    <>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <VerticalCenterLayout>
            <img src={submitted} className={classes.img} alt="submitted" />
          </VerticalCenterLayout>
          <Box mt={13} mb={4}>
            <Typography variant="h4" align="center">
              <Trans>Claim request submitted!</Trans>
            </Typography>
          </Box>
          <Box textAlign="center" color={Colors.lightSteelBlue}>
            <Typography variant="subtitle1">
              <Trans>
                We will notify you once the claim is approved or rejected.
                Please check your email for updates.
              </Trans>
            </Typography>
          </Box>
          <Box
            mt={10}
            mb={5}
            display="flex"
            textAlign="center"
            justifyContent="center"
          >
            <Typography onClick={jumpToHome} className={classes.link}>
              <Trans>Back to Policy</Trans>
            </Typography>
            <KeyboardArrowRight />
          </Box>
        </Box>
      </Container>
    </>
  );
};

ClaimSubmitSuccess.propTypes = {
  history: RRPropTypes.history.isRequired,
};

export default ClaimSubmitSuccess;
