export function getJWT(platform) {
  const jwtToken = localStorage.getItem(`${platform}_jwt`);
  return jwtToken;
}

export function setJWT(platform, token) {
  localStorage.setItem(`${platform}_jwt`, token);
}

export function removeJWT(platform) {
  localStorage.removeItem(`${platform}_jwt`);
}

export function parseJWT(token) {
  try {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
    return null;
  } catch (error) {
    return null;
  }
}
