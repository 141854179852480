export default {
  CLAIM_PROCESSING: 'Claim Processing',
  CLAIM_CANCELLED: 'Claim Cancelled',
  CLAIM_REJECTED: 'Claim Rejected',
  CLAIM_PENDING: 'Claim Pending',
  CLAIM_APPROVED: 'Claim Approved',
  CLAIM_EXPIRED: 'Claim Expired',
  REIMBURSED: 'Reimbursement Reimbursed',
  REIMBURSEMENT_PROCESSING: 'Reimbursement Processing',
  REIMBURSEMENT_FAILED: 'Reimbursement Failed',
  REIMBURSEMENT_CANCELLED: 'Reimbursement Cancelled',
};
