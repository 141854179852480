import apisauce from 'apisauce';

const create = (baseURL = 'http://localhost:4000') => {
  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {},
    // 1 minute timeout...
    timeout: 60000,
    withCredentials: true,
  });

  const appendHeaders = headers => {
    const newHeaders = {
      ...headers,
    };
    api.setHeaders(newHeaders);
  };

  return {
    appendHeaders,
  };
};

export default create();
