import React, { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { decamelizeKeys } from 'humps';
import { parse, format } from 'date-fns';
import { useSearchParam } from 'react-use';
import defaultsDeep from 'lodash/defaultsDeep';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Checkbox, FormControlLabel } from '@material-ui/core';

import api from '@platform/api';
import { telkomselFontFamily } from 'variables';
import MessageBar from 'components/MessageBar';
import LinearLoadingBar from 'components/LinearLoadingBar';
import { getErrors, setErrors as setFormErrors } from 'components/Form';

import Address from './Address';
import InsuredDetails from './InsuredDetails';
import Contact from './components/Contact';
import BottomIcon from './components/BottomIcon';
import ConfirmModal from './components/ConfirmModal';
import Typography from './components/AllianzTypography';

import calculateAge from '../utils/calculateAge';
import allianzTelkomselIcon from './img/allianz-telkomsel.svg';

const AGE_LIMIT = 17;
const DATE_FORMAT = 'dd-MM-yyyy';
const REQUEST_FORMAT = 'yyyy-MM-dd';

const useStyle = makeStyles(() => ({
  submit: {
    fontSize: 16,
    color: 'white',
    background: '#006192',
    fontFamily: telkomselFontFamily,
    '&:hover': {
      background: '#006192',
    },
  },
  termsAndConditions: {
    lineHeight: 4,
    cursor: 'pointer',
    marginLeft: '-10px',
    color: 'rgb(79, 133, 168)',
  },
}));

const Activation = () => {
  const styles = useStyle();
  const activationId = useSearchParam('aid');
  const [open, setOpen] = useState(false);
  const [prefix, setPrefix] = useState('0811');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState({});
  const [insuredDetails, setInsuredDetails] = useState({});

  const handleChange = type => name => e => {
    const val = get(e, 'target.value', e);
    if (type === 'address') setAddress({ ...address, [name]: val });
    if (type === 'insuredDetails')
      setInsuredDetails({ ...insuredDetails, [name]: val });
  };

  const handleSubmit = async () => {
    if (!checked) return;
    // check field whether is valid
    let checkedErrors = null;
    const dateOfBirth = get(insuredDetails, 'dateOfBirth');
    const age = calculateAge(parse(dateOfBirth, DATE_FORMAT, new Date()));
    if (dateOfBirth && age < AGE_LIMIT) {
      checkedErrors = setFormErrors([
        {
          field: 'insuredDetails',
          name: 'dateOfBirth',
          errorMsg: (
            <Trans>
              The age does not fall within the requirement. Please correct the
              date or consider changing the insured.
            </Trans>
          ),
        },
      ]);
    }
    const mobile = String(get(insuredDetails, 'mobileNumber'), '');
    const mobileErrors = setFormErrors([
      {
        field: 'insuredDetails',
        name: 'mobileNumber',
        errorMsg: <Trans>The phone number you entered is invalid</Trans>,
      },
    ]);
    if (prefix === '0811') {
      if (mobile.length !== 6 && mobile.length !== 7)
        checkedErrors = mobileErrors;
    } else if (prefix === '0812') {
      if (mobile.length !== 7 && mobile.length !== 8)
        checkedErrors = mobileErrors;
    } else if (mobile.length !== 8) checkedErrors = mobileErrors;

    const formErrors = getErrors();
    checkedErrors = defaultsDeep(checkedErrors, formErrors);
    if (!isEmpty(checkedErrors)) {
      setErrors(checkedErrors);
      return;
    }

    setLoading(true);

    // build data to send request
    const requestBody = {
      insured_details: {
        ...decamelizeKeys(insuredDetails),
        ...{
          date_of_birth: format(
            parse(dateOfBirth, DATE_FORMAT, new Date()),
            REQUEST_FORMAT
          ),
        },
        address: decamelizeKeys(address),
      },
    };

    requestBody.insured_details.mobile_number =
      prefix + requestBody.insured_details.mobile_number;

    try {
      const { data } = await api.activateActivation(requestBody, activationId);
      if (!isEmpty(data) && data.otp_url) {
        window.location = data.otp_url;
      }
    } catch (e) {
      setApiError(
        <Trans>
          Something went wrong, please contact our customer service.
        </Trans>
      );
    }
    setLoading(false);
  };

  return (
    <>
      <LinearLoadingBar loading={loading} />
      <Box display="flex" justifyContent="center" marginBottom={3}>
        <img src={allianzTelkomselIcon} alt="allianz-telkomsel" />
      </Box>
      <Typography variant="h1">
        <Trans>Activation Form</Trans>
      </Typography>
      <Box color="#6B778C" marginY={2}>
        <Typography variant="subtitle2">
          <Trans>
            Please make sure the information entered below is correct. Once
            confirmed, it cannot be changed.
          </Trans>
        </Typography>
      </Box>
      <form>
        <Box marginY={4}>
          <Box color="#FF934F">
            <Typography variant="h5">
              <Trans>1. Insured Details</Trans>
            </Typography>
          </Box>
          <Box bgcolor="#FAFCFD">
            <InsuredDetails
              errors={errors}
              insuredDetails={insuredDetails}
              prefix={prefix}
              prefixChange={e => setPrefix(e.target.value)}
              handleChange={handleChange('insuredDetails')}
            />
            <Address
              errors={errors}
              address={address}
              handleChange={handleChange('address')}
            />
          </Box>
        </Box>
        <Box bgcolor="#FAFCFD" marginY={4} display="flex" paddingLeft={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="subtitle2">
                <Trans>I agree to the</Trans>
              </Typography>
            }
          />
          <Typography
            variant="subtitle2"
            className={styles.termsAndConditions}
            onClick={() => setOpen(true)}
          >
            <Trans>Terms and Conditions</Trans>
          </Typography>
        </Box>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          className={styles.submit}
        >
          <Trans>Submit</Trans>
        </Button>
      </form>
      <Contact />
      <BottomIcon />
      <MessageBar
        variant="error"
        open={!!apiError}
        message={apiError}
        handleClose={() => setApiError(null)}
      />
      <ConfirmModal open={open} closeModal={() => setOpen(false)} />
    </>
  );
};

export default Activation;
