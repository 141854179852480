import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox as MuiCheckbox,
  FormHelperText,
  Typography,
} from '@material-ui/core';

import { ReactComponent as EmptyIcon } from './empty.svg';
import { ReactComponent as CheckedIcon } from './checked.svg';

function Checkbox({ label, value, onChange, name, errors, checkedIcon }) {
  return (
    <>
      <Box display="flex" alignItems="center" height="100%">
        <MuiCheckbox
          icon={<EmptyIcon />}
          checkedIcon={checkedIcon || <CheckedIcon />}
          checked={value}
          onChange={e => onChange(e.target.checked)}
        />
        <Typography variant="subtitle2" color="secondary">
          {label}
        </Typography>
      </Box>
      {!!errors[name] && <FormHelperText error>{errors[name]}</FormHelperText>}
    </>
  );
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  name: PropTypes.string,
  checkedIcon: PropTypes.node,
};

Checkbox.defaultProps = {
  value: false,
  onChange: () => {},
  errors: {},
  name: '',
  checkedIcon: null,
};

export default Checkbox;
